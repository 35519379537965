export const paymentContractAddressPolygon = "0x08bd700c054035d518BbC006f763ca2003F68d5f"; //"0xACfd22bF79bD1976500553D03FEA9424dFDD25E8" //"0xde3554C6B892d725BF4bfcC5b867ed58560E7f4A" //"0xa26cb4a893C270Fd6f93052c11B1c8C457117259";
export const paymentContractAddressEth = "0xd040d0ad5b5b60D41fd9fFDdA0A0Ed1F9c42d71e";
export const paymentContractAddressAvax = "0x72B9Da1BBBDb255F94989A8075e307A2B904657f";
export const paymentContractAddressBnb = "0x336dc37F96A459FF365554266F9CC27FFA744Bfd";
export const paymentContractAddressFtm = "0xecFb76A4fA3Ba73B2B90f18c14E4BEaadf482e2f";
export const paymentContractAddressArb = "0x6f8FF8466348138dC48Ee044293976a4DeEA9edA";
export const paymentContractAddressOp = "0x7E594B1d9A670B12Ade1fB23d178030F38f918E4";
export const paymentContractAddressBase = "0xc59847B484BC9a4dA2fb6B03C95878A34DD28545";

export const paymentContractAddressPolygonNFT = "0x559a28Db24F3aF775D3AeD46bedC1deC2a0B6EE0" //"0x7867109606071f28bEF3E76881cf423b2322BB03"; // 0x481e78F38c75a5ccceDa13e5A49fF7E8484DB4BF //"0xb7E140e38338f621437CFcDD8ebCCb5E997e2660"; //"0x4047dc16ff115De261F6b152e6938718A42dDaAa" //"0x5BEF550f2A87ee420C7E95DFB6A5B9Af25AbC45c" //"0xd242E300d692e692B0f529F5F4a0Ce31fADF38D1";
export const paymentContractAddressEthNFT = "0xd040d0ad5b5b60D41fd9fFDdA0A0Ed1F9c42d71e";
export const paymentContractAddressAvaxNFT = "0xF81353Dd47E09f4b4Ac41986A9C6050f424962eF";
export const paymentContractAddressBnbNFT = "0xBc7593751E30c1F44B2A7D2A18C7b7CA688C2f4c";
export const paymentContractAddressFtmNFT = "0xcfFAcc16DA502F02165130d2B1789FE76118E6A8";
export const paymentContractAddressArbNFT = "0x77b056a043fddc607B042C1B41383CC742cA2459";
export const paymentContractAddressOpNFT = "0xE0e4aB1Bc5faB6aae2a0A26Aa82Db95082118D58";
export const paymentContractAddressBaseNFT = "0xbd0E39f3e805926d70d4dB94038f883C9B40459B";