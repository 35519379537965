import React, { useState, useEffect } from 'react';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import { Button, useNotification, Loading } from '@web3uikit/core';
import coin from '../images/logo2.png'
import Chains from '../components/chains-SP';
import Benefits from '../components/benefits-SP';
import SocialMedia from '../components/socialMedia-SP';
import About from '../components/about-SP';
import Footer from '../components/footer-SP';
import Home from './Home-SP';
import tg from '../images/telegram2.png';
import mail from '../images/email2.png';
import twitter from '../images/twitter2.png';
import navbar from '../images/navigation-bar.png';
import wrong from '../images/wrong.png';
import translate from '../images/translate.png';
import About2SP from '../components/about2-SP';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const MainSP = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();
    const [_navbarOpen, set_navbarOpen] = useState(0);

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    return (
        <>
            <div class="allWrap">
                <div class="light">

                    <div class="cont">
                        <div class="headers">

                            <div class="h1">
                                <div class="logoDiv">
                                    Tokeniza
                                </div>

                                <div class="connect">
                                    <div><Link activeClass="" id="fontSize" to="chains" spy={true} smooth={true} duration={550}>CADENAS</Link></div>
                                    <div><Link activeClass="" id="fontSize" to="tokenize" spy={true} smooth={true} duration={550}>Tokenize</Link></div>
                                    <div><Link activeClass="" id="fontSize" to="register" spy={true} smooth={true} duration={550}>Conectar</Link></div>
                                    <div><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>Acerca de</Link></div>
                                    <div id="fontSize" onClick={() => window.location.href = 'nft-sp'}>NFT</div>

                                    <div class="connect2">
                                        <button class="connectBtn" onClick={() => window.location.href = 'token-dashboard-sp'}>PANEL</button>
                                    </div>
                                </div>

                            </div>

                        </div>

                        {_navbarOpen < 1 ?
                            (<div class="miniBarMain">
                                <div class="logoDiv">Tokeniza</div>

                                <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                            </div>) :
                            (<div class="miniBarMain">
                                <div class="logoDiv">Tokeniza</div>

                                <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                            </div>)}

                        {_navbarOpen > 0 ?
                            (<div class="littleNav">
                                <div><Link activeClass="" id="fontSize" to="chains" spy={true} smooth={true} duration={550}>CADENAS</Link></div>
                                <div><Link activeClass="" id="fontSize" to="tokenize" spy={true} smooth={true} duration={550}>Tokenize</Link></div>
                                <div><Link activeClass="" id="fontSize" to="register" spy={true} smooth={true} duration={550}>Conectar</Link></div>
                                <div><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>Acerca de</Link></div>
                                <div id="fontSize" onClick={() => window.location.href = 'nft-sp'}>NFT</div>

                                <div>
                                    <span class="connect2">
                                        <button class="connectBtn" onClick={() => window.location.href = 'token-dashboard-sp'}>PANEL</button>
                                    </span>
                                </div>
                                <div class="socialIcon">
                                    <img src={twitter} />
                                    <img src={mail} />
                                    <img src={tg} />

                                </div>

                            </div>) : null}

                        <Element name="home">
                            <div class="introductionMain">

                                <img class="coinMob" src={coin} />

                                <div class="introduction">
                                    <div class="in1">Transforma activos en oportunidades digitales</div>
                                    <div class="in2">Abriendo camino hacia el futuro a través de la creación y gestión de tokens </div>
                                    <div class="in3">Somos la única empresa full service en México especializada en la tokenización de activos. Te asesoramos en cada etapa del proceso legal y tecnológico del lanzamiento de tu token, garantizando una tokenización integral tanto off-chain como on-chain.</div>
                                    <p></p>
                                    <div class="in3">Somos un equipo de profesionales dedicados a brindar servicios de tokenización excepcionales.</div>
                                    <p></p>

                                    <form class="connect2">
                                        <Link activeClass="" to="tokenize" spy={true} smooth={true} duration={550}><button class="connectBtn2">Explorar</button></Link>
                                    </form>

                                </div>

                                <img class="coin" src={coin} />
                            </div>
                        </Element>
                    </div>

                    <Element name="chains">
                        <Chains />
                    </Element>

                    <Element name="tokenize">
                        <Benefits />
                    </Element>

                    <Element name="register">
                        <Home />
                    </Element>

                    <SocialMedia />

                    <Element name="about">
                        <About />
                    </Element>

                    <About2SP />

                    <Footer />

                </div>

                <div className='stickyBtn'><img class="stickyBtn2"  onClick={() => window.location.href = '/home-en'}src={translate} /></div>

            </div>

        </>
    );
};

export default MainSP;
