import React, { useState, useEffect } from 'react';
import CompileComponent from './CompileComponent';
import Register from './Register';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import deploy from '../images/useCases.png';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';

const About2 = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    return (
        <>
            <div class="aboutMain">

                <div class="aboutMain2">

                    <div class="about2" data-aos="fade" data-aos-duration="500">
                        <div class="aboutT">Tokenization use cases</div>
                        <div class="aboutT2"><span className='leftT'>Tokeniza specializes on the tokenization of</span></div>
                        <div className='centered'>

                        <p className='aboutH2'>1. Financial products </p>
                        <p className='aboutH2'>2. Company shares – capital stock</p>
                        <div class="aboutT2"><li>Real Estate </li></div>
                        <div class="aboutT2"><li>Art </li></div>
                        <div class="aboutT2"><li>Tickets </li></div>
                        <div class="aboutT2"><li>Supply Chain and Logistics</li></div>
                        <div class="aboutT2"><li>Crowdfunding and Venture Capital</li></div>
                        <div class="aboutT2"><li>Intellectual Property Rights</li></div>
                        </div>
                        <p></p>
                        <p class="aboutT2"><span className='color'>And more. Don’t hesitate to ask, we will assess your business and identify possible tailor-made tokenization use cases.</span></p>

                    </div>

                    <img class="deploy2" src={deploy} data-aos="zoom-in" data-aos-duration="500" />

                </div>
            </div>

        </>
    );
};

export default About2;