import React, { useState, useEffect } from 'react';
import CompileComponent from './CompileComponent';
import Register from './Register';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import deploy from '../images/Pickaxe.png';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';

const About = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    return (
        <>
            <div class="aboutMain">

                <div class="aboutMain2">

                    <img class="deploy" src={deploy} data-aos="zoom-in" data-aos-duration="500" />

                    <div class="about2" data-aos="fade" data-aos-duration="500">
                        <div class="aboutT">True tokenization experts</div>
                        <div class="aboutT2">We are true tokenization experts, committed with excellence, cutting-edge technology and unwavering dedication. We provide a seamless and secure tokenization experience, empowering businesses.</div>

                        <div className='centered'>

                            <p className='aboutH2'>What is tokenizing assets?</p>
                            <div class="aboutT2"><li>It is the digitization of property rights over assets, tangible or intangible, by creating tokens on a blockchain network. </li></div>
                            <div class="aboutT2"><li>Each token represents ownership or a fractional share of ownership of the underlying asset, which can be fungible or non-fungible.</li></div>
                            <div class="aboutT2"><li>Blockchain, on the other hand,  is the technology that allows the decentralized, permanent and unalterable record of digital transactions. Each block in the chain contains a set of verified transactions and is cryptographically linked to the previous block, thus forming a continuous chain of blocks. This ensures that the information stored on the blockchain is immutable and extremely difficult to modify without the consensus of the majority of nodes on the network.</li></div>
                        </div>

                        <div className='centered'>
                            <p className='aboutH2'>Proceeds</p>
                            <div class="aboutT2-22">Asset tokenization is an innovative way to increase accessibility, liquidity, and efficiency, as well as asset transparency and traceability.</div>
                            <p></p>
                            <div class="aboutT2"><li><span className='bB'>Fractionation and accessibility: </span>Facilitates investment for a wider audience. That is, it facilitates the fractional acquisition of assets that would otherwise be too expensive or inaccessible.</li></div>
                            <div class="aboutT2"><li><span className='bB'>Liquidity: </span>It can increase the liquidity of assets as it facilitates their circulation (buying and selling) in secondary markets.</li></div>
                            <div class="aboutT2"><li><span className='bB'>Cost reduction and efficiency: </span>By using blockchain technologies  and smart contracts, intermediaries can be eliminated and transaction processes simplified.</li></div>
                            <div class="aboutT2"><li><span className='bB'>Transparency and traceability: </span>It allows you to track the complete history of an asset from its issuance to its current possession.</li></div>
                            <div class="aboutT2"><li><span className='bB'>Global Access: </span>It can remove geographical barriers by making it easier for investors around the world to access a wider variety of assets.</li></div>
                            <div class="aboutT2"><li><span className='bB'>Automation: </span>Smart contracts built into asset tokenization can automate certain aspects of asset-related operations, such as dividend payments or deal execution.</li></div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};

export default About;