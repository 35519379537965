import React, { useState, useEffect } from 'react';
import CompileComponent from './CompileComponent';
import Register from './Register';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import tg from '../images/telegram2.png';
import mail from '../images/email2.png';
import twitter from '../images/twitter2.png';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Footer = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    return (
        <>
            <div class="footerMain">

                <div class="iconsF">
                    <img src={twitter} />
                    <img src={mail} />
                    <img src={tg} />
                </div>

                <div class="footer1">Terms of Use . Privacy Policy</div>
                <div class="footer1">Copyright © 2023 Tokeniza. All Rights Reserved</div>

            </div>

        </>
    );
};

export default Footer;