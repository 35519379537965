import React, { useState, useEffect } from 'react';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import settings from '../images/settings.png';

const Process = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    return (
        <>
            <div class="processMain">

                <div class="aboutMain2">

                    <div class="about2" data-aos="fade" data-aos-duration="500">
                        <div class="aboutT">PROCESS</div>
                        <div class="aboutT2">1. Signature of NDA Tokeniza NFTs - Entity that owns the work.</div>
                        <div class="aboutT2">2. Signing of the contract for the provision of services.</div>
                        <div class="aboutT2">3. Digitization of the collection.</div>
                        <div class="aboutT2">4. Minted from the NFT.</div>
                        <div class="aboutT2">5. Market release.</div>
                        <div class="aboutT2">6. Collection marketing.</div>
                        <div class="aboutT2">7. Leasing of the work(s).</div>
                        <div class="aboutT2">8. Dispersal of funds.</div>
                    </div>

                    <img class="deploy" src={settings} data-aos="zoom-in" data-aos-duration="500" />

                </div>
            </div>

        </>
    );
};

export default Process;