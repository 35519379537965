import React, { useState, useEffect } from 'react';
import '../pages/main.css';
import '../pages/nft.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Footer from '../components/footer-SP';
import Home from './nftHome-sp';
import tg from '../images/telegram2.png';
import mail from '../images/email2.png';
import twitter from '../images/twitter2.png';
import navbar from '../images/navigation-bar.png';
import wrong from '../images/wrong.png';
import img1 from '../images/nft.jpeg';
import img2 from '../images/img1.jpeg';
import img3 from '../images/img2.jpeg';
import img4 from '../images/img3.jpeg';
import img5 from '../images/img4.jpeg';
import Process from '../components/process-SP';
import FAQ from '../components/faq-SP';
import Carousel from '../components/carousel';
import translate from '../images/translate.png';

var Scroll = require('react-scroll');

let signer;
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

const NFT = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();
    const [_navbarOpen, set_navbarOpen] = useState(0);

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    return (
        <>
            <div class="allWrap">
                <div class="light">

                    <div class="cont2">
                        <div class="headers">

                            <div class="h1">
                                <div class="logoDiv">
                                    Tokeniza
                                </div>

                                <div class="connect">
                                <div><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>ACERCA DE</Link></div>
                                <div><Link activeClass="" id="fontSize" to="process" spy={true} smooth={true} duration={550}>PROCESO</Link></div>
                                <div><Link activeClass="" id="fontSize" to="connect" spy={true} smooth={true} duration={550}>CONECTAR</Link></div>
                                <div><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>Preguntas frecuentes</Link></div>
                                <div id="fontSize" onClick={() => window.location.href = '/'}>Token</div>

                                    <div class="connect2">
                                        <button class="connectBtn" onClick={() => window.location.href = 'nft-dashboard-sp'}>Panel</button>
                                    </div>
                                </div>

                            </div>

                        </div>

                        {_navbarOpen < 1 ?
                            (<div class="miniBarMain">
                                <div class="logoDiv"><span class="dashH" onClick={() => window.location.href = 'nft'}>Tokeniza</span>
                                </div>

                                <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
                            </div>) :
                            (<div class="miniBarMain">
                                <div class="logoDiv"><span class="dashH" onClick={() => window.location.href = 'nft'}>Tokeniza</span>
                                </div>

                                <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
                            </div>)}

                        {_navbarOpen > 0 ?
                            (<div class="littleNav">
                                <div><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>ACERCA DE</Link></div>
                                <div><Link activeClass="" id="fontSize" to="process" spy={true} smooth={true} duration={550}>PROCESO</Link></div>
                                <div><Link activeClass="" id="fontSize" to="connect" spy={true} smooth={true} duration={550}>CONECTAR</Link></div>
                                <div><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>Preguntas frecuentes</Link></div>
                                <div id="fontSize" onClick={() => window.location.href = '/'}>Token</div>

                                <div>
                                    <span class="connect2">
                                        <button class="connectBtn" onClick={() => window.location.href = 'nft-dashboard-sp'}>Panel</button>
                                    </span>
                                </div>
                                <div class="socialIcon">
                                    <img src={twitter} />
                                    <img src={mail} />
                                    <img src={tg} />

                                </div>

                            </div>) : null}

                        <Element name="home">

                            <div className='nftMain'>
                                 <div className='nftMainH'>Verdaderos expertos en tokenización</div>
                                <div className='nftMainP'>Somos expertos en tokenización comprometidos con la excelencia y con la tecnología de vanguardia; trabajamos con dedicación inquebrantable para revolucionar la manera en que se preservan y negocian los activos en México y en el mundo. Ofrecemos una experiencia de tokenización fluida y segura, potenciando a las empresas.</div>
                                <div className='nftMainP'>Transforma activos en oportunidades digitales a través de NFTs.</div>
                                <div className='nftMainP'>Los NFTs son parte de la transformación digital y están revolucionando la manera en que se negocian y preservan los activos en México y en el mundo.</div>
                                <div className='nftMainP'>Convierte activos a NFTs con Tokeniza y participa en la economía digital de forma segura, transparente y eficiente.</div>
                            </div>

                            <Carousel />

                        </Element>
                    </div>

                    <Element name="about">
                        <div className='forPC'>
                        <div class="aboutMain">

                            <div class="aboutT-2">Tokeniza NFT es un proyecto innovador que ofrece multitud de utilidades a los usuarios:</div>

                            <div class="aboutMain2">

                                <img class="deploy" src={img2} data-aos="zoom-in" data-aos-duration="500" />

                                <div class="about2" data-aos="fade" data-aos-duration="500">
                                    <div class="aboutT2-2">Digitalización de activos: convierta activos físicos en NFT, garantizando autenticidad y procedencia.</div>
                                </div>

                            </div>

                            <div class="aboutMain2">


                                <div class="about2" data-aos="fade" data-aos-duration="500">
                                    <div class="aboutT2-2">Mercado de alquiler: arriende activos del mundo real como NFT, aplicados mediante contratos inteligentes para su cumplimiento.</div>
                                </div>

                                <img class="deploy" src={img1} data-aos="zoom-in" data-aos-duration="500" />


                            </div>

                            <div class="aboutMain2">

                                <img class="deploy" src={img3} data-aos="zoom-in" data-aos-duration="500" />

                                <div class="about2" data-aos="fade" data-aos-duration="500">
                                    <div class="aboutT2-2">Propiedad fraccionada: habilite la propiedad compartida de activos mediante tokenización.</div>
                                </div>

                            </div>


                            <div class="aboutMain2">


                                <div class="about2" data-aos="fade" data-aos-duration="500">
                                    <div class="aboutT2-2">Gamificación: explore experiencias gamificadas aprovechando NFT y activos.</div>
                                </div>
                                <img class="deploy" src={img4} data-aos="zoom-in" data-aos-duration="500" />

                            </div>
                            <div class="aboutMain2">

                                <img class="deploy" src={img5} data-aos="zoom-in" data-aos-duration="500" />

                                <div class="about2" data-aos="fade" data-aos-duration="500">
                                    <div class="aboutT2-2">Tokenización de propiedad intelectual: tokenice los derechos de propiedad intelectual para una propiedad e intercambio seguros.</div>
                                </div>

                            </div>


                        </div>
                        </div>

                        <div className='forMob'>
                        <div class="aboutMain">

                            <div class="aboutT-2">Tokeniza NFT es un proyecto innovador que ofrece multitud de utilidades a los usuarios:</div>

                            <div class="aboutMain2">

                                <img class="deploy" src={img2} data-aos="zoom-in" data-aos-duration="500" />

                                <div class="about2" data-aos="fade" data-aos-duration="500">
                                    <div class="aboutT2-2">Digitalización de activos: convierta activos físicos en NFT, garantizando autenticidad y procedencia.</div>
                                </div>

                            </div>

                            <div class="aboutMain2">

                            <img class="deploy" src={img1} data-aos="zoom-in" data-aos-duration="500" />

                                <div class="about2" data-aos="fade" data-aos-duration="500">
                                    <div class="aboutT2-2">Mercado de alquiler: arriende activos del mundo real como NFT, aplicados mediante contratos inteligentes para su cumplimiento.</div>
                                </div>



                            </div>

                            <div class="aboutMain2">

                                <img class="deploy" src={img3} data-aos="zoom-in" data-aos-duration="500" />

                                <div class="about2" data-aos="fade" data-aos-duration="500">
                                    <div class="aboutT2-2">Propiedad fraccionada: habilite la propiedad compartida de activos mediante tokenización.</div>
                                </div>

                            </div>


                            <div class="aboutMain2">

                            <img class="deploy" src={img4} data-aos="zoom-in" data-aos-duration="500" />

                                <div class="about2" data-aos="fade" data-aos-duration="500">
                                    <div class="aboutT2-2">Gamificación: explore experiencias gamificadas aprovechando NFT y activos.</div>
                                </div>

                            </div>
                            <div class="aboutMain2">

                                <img class="deploy" src={img5} data-aos="zoom-in" data-aos-duration="500" />

                                <div class="about2" data-aos="fade" data-aos-duration="500">
                                    <div class="aboutT2-2">Tokenización de propiedad intelectual: tokenice los derechos de propiedad intelectual para una propiedad e intercambio seguros.</div>
                                </div>

                            </div>


                        </div>
                        </div>
                    </Element>

                    <Element name="connect">
                        <Home />
                    </Element>

                    <Element name="process">
                        <Process />
                    </Element>

                    <Element name="faq">
                        <FAQ />
                    </Element>

                    <Footer />

                </div>

                <div className='stickyBtn'><img class="stickyBtn2"  onClick={() => window.location.href = 'nft'}src={translate} /></div>

            </div>

        </>
    );
};

export default NFT;
