import React, { useState, useEffect } from 'react';
import CompileComponent from './CompileComponent';
import Register from './Register';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import deploy from '../images/Pickaxe.png';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';

const About = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    return (
        <>
            <div class="aboutMain">

                <div class="aboutMain2">

                    <img class="deploy" src={deploy} data-aos="zoom-in" data-aos-duration="500" />

                    <div class="about2" data-aos="fade" data-aos-duration="500">
                        <div class="aboutT">Construido por verdaderos expertos en tokenización</div>
                        <div class="aboutT2">Creado por expertos en tokenización, nuestra plataforma refleja tecnología de vanguardia y dedicación inquebrantable. Con un compromiso con la excelencia, proporcionamos una experiencia de tokenización fluida y segura, empoderando a las empresas en el panorama de transacciones digitales.</div>
                        <div class="aboutT2">Tokeniza es la única empresa full service en México para la tokenización de activos. Te asesoramos en todo el proceso legal y tecnológico para el lanzamiento de tu token.</div>

                        <div className='centered'>

                            <p className='aboutH2'>¿Qué es la tokenización de activos?</p>
                            <div class="aboutT2"><li>Se trata de la digitalización de los derechos de propiedad sobre activos, tangibles o intangibles, mediante la creación de tokens en una red blockchain.</li></div>
                            <div class="aboutT2"><li>Cada token representa la propiedad o una fracción de la propiedad del activo subyacente, que puede ser fungible o no fungible.</li></div>
                            <div class="aboutT2"><li>Blockchain, por su parte, es la tecnología que permite el registro descentralizado, permanente e inalterable de las transacciones digitales. Cada bloque de la cadena contiene un conjunto de transacciones verificadas y está vinculado criptográficamente al bloque anterior, formando así una cadena continua de bloques. Esto asegura que la información almacenada en la cadena de bloques sea inmutable y extremadamente difícil de modificar sin el consenso de la mayoría de los nodos de la red.</li></div>
                        </div>

                        <div className='centered'>
                            <p className='aboutH2'>Producto</p>
                            <div class="aboutT2-22">La tokenización de activos es una forma innovadora de aumentar la accesibilidad, la liquidez y la eficiencia, así como la transparencia y la trazabilidad de los activos.</div>
                            <p></p>
                            <div class="aboutT2"><li><span className='bB'>Fraccionamiento y accesibilidad: </span>Facilita la inversión para un público más amplio. Es decir, facilita la adquisición fraccionada de activos que de otro modo serían demasiado caros o inaccesibles.</li></div>
                            <div class="aboutT2"><li><span className='bB'>Liquidez: </span>Puede aumentar la liquidez de los activos ya que facilita su circulación (compra y venta) en los mercados secundarios.</li></div>
                            <div class="aboutT2"><li><span className='bB'>Reducción de costes y eficiencia: </span>Mediante el uso de tecnologías blockchain y contratos inteligentes, se pueden eliminar intermediarios y simplificar los procesos de transacción.</li></div>
                            <div class="aboutT2"><li><span className='bB'>Transparencia y trazabilidad: </span>Le permite rastrear el historial completo de un activo desde su emisión hasta su posesión actual.</li></div>
                            <div class="aboutT2"><li><span className='bB'>Acceso global: </span>Puede eliminar barreras geográficas al facilitar que los inversores de todo el mundo accedan a una variedad más amplia de activos.</li></div>
                            <div class="aboutT2"><li><span className='bB'>Automatización: </span>Los contratos inteligentes integrados en la tokenización de activos pueden automatizar ciertos aspectos de las operaciones relacionadas con activos, como el pago de dividendos o la ejecución de transacciones.</li></div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};

export default About;