import React, { Component } from 'react';

class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqbg">

        <div class="accordin reveal">

          <div class="aboutT">FAQ sobre Tokenización de NFTs en Tokeniza</div>

          <details >
            <summary>¿Qué es un NFT?</summary>
            <div class="faq__content">
              <p>Un NFT, o Token No Fungible, es un tipo de token digital único en la blockchain que representa la propiedad o autenticidad de un activo digital o físico. A diferencia de las criptomonedas, cada NFT tiene un valor único y no es intercambiable de manera directa por otro NFT.</p>
            </div>
          </details>
          <details>
            <summary>¿Cómo puedo tokenizar un activo como NFT en Tokeniza?</summary>
            <div class="faq__content">
              <p>Para tokenizar un activo como NFT en Tokeniza, el proceso inicia con tu registro en nuestra plataforma. Una vez registrado, deberás completar un formulario detallado sobre el activo que deseas tokenizar. Por ejemplo, si es una obra de arte digital, necesitarás proporcionar una descripción de la obra, el nombre del artista, el año de creación, y adjuntar una imagen de alta resolución del arte. También es importante que definas el valor que consideras que tiene tu activo, aunque nuestro equipo puede asistirte en la evaluación.</p>
            </div>
          </details>
          <details>
            <summary>¿Qué tipo de activos puedo tokenizar en NFTs?</summary>
            <div class="faq__content">
              <p>En Tokeniza puedes tokenizar una amplia gama de activos, incluyendo:</p>
              <p>Obras de Arte Digitales: Artistas pueden tokenizar sus obras digitales, permitiendo a los coleccionistas adquirir derechos de propiedad digitales exclusivos. </p>
              <p>Música: Músicos y compositores pueden tokenizar sus pistas o álbumes, creando una edición limitada de su música. </p>
              <p>Videos: Creadores de contenido pueden tokenizar videos, desde cortometrajes hasta momentos icónicos de eventos, proporcionando una manera única para que los seguidores posean un momento digitalizado en la historia.</p>
              <p>Propiedades Físicas y Derechos de Autor: Tokeniza ofrece la posibilidad de tokenizar derechos de propiedad sobre bienes físicos y derechos de autor, abriendo nuevas vías para la inversión y la distribución de valor.</p>
            </div>
          </details>

          <details>
            <summary>¿Son seguros los NFTs en Tokeniza?</summary>
            <div class="faq__content">
              <p>La seguridad es una prioridad en Tokeniza. Utilizamos la tecnología blockchain para asegurar que cada NFT sea auténtico y su propiedad esté claramente definida y protegida. Además, implementamos medidas de seguridad adicionales para proteger los activos y la información de nuestros usuarios.</p>
            </div>
          </details>

          <details>
            <summary>¿DÓNDE SE COLOCAN LOS NFT’s?</summary>
            <div class="faq__content">
              <p>Hoteles , lobbys , habitaciones , ocinas o ¡hasta en tu casa! Cualquier persona o entidad
                que quiera arrendar por un tiempo determinado el NFT de una obra original y exhibirla en
                cualquier pantalla.</p>
            </div>
          </details>

        </div>
      </div>


    )
  }
}

export default FAQ;

