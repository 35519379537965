import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';

const CompileAndDeployComponent = (props) => {
    const [response, setResponse] = useState('');
    const [_ContractName, set_ContractName] = useState('');
    const [_ContractSymbol, set_ContractSymbol] = useState('');
    const [_TotalSupply, set_TotalSupply] = useState('');
    const [_TokenTransferAddress, set_TokenTransferAddress] = useState('');
    const userAddress = props.userAddress;
    const getLastPaymentRecordIndex = props.getLastPaymentRecordIndex;
    const _selectededNetwork = props._ChainID;
    const navigate = useNavigate();
    const [_Series, set_Series] = useState('');
    const [_OtraInformacion, set_OtraInformacion] = useState('');
    const [_RequisitosLegales, set_RequisitosLegales] = useState('');

    const [_baseuri, set_baseuri] = useState('');
    const [_notrevealeduri, set_notrevealeduri] = useState('');
    const [_contracturi, set_contracturi] = useState('');
    const [_givenMaxSupply, set_givenMaxSupply] = useState('');
    const [errorMessage, setErrorMessage] = useState(""); // State to hold error message
    const [errorMessage1, setErrorMessage1] = useState(""); // State to hold error message
    const [errorMessage2, setErrorMessage2] = useState(""); // State to hold error message
    const [errorMessage3, setErrorMessage3] = useState(""); // State to hold error message
    const [errorMessage4, setErrorMessage4] = useState(""); // State to hold error message
    const [errorMessage5, setErrorMessage5] = useState(""); // State to hold error message
    const [errorMessage6, setErrorMessage6] = useState(""); // State to hold error message

    const [errorMessages, setErrorMessages] = useState({
        _ContractName: '',
        _Series: '',
        _ContractSymbol: '',
        _TotalSupply: '',
        _TokenTransferAddress: '',
        _givenMaxSupply: ''
    });

    const isAnyInputEmpty = () => {
        return (
            !_ContractName ||
            !_Series ||
            !_ContractSymbol ||
            !_TotalSupply ||
            !_TokenTransferAddress ||
            !_givenMaxSupply
        );
    };

    const isButtonDisabled = isAnyInputEmpty();

    useEffect(() => {
        console.log("_ChainID_USEEFFECT : " + _selectededNetwork);
    }, []);

    {/*const handleCompileAndDeploy = async () => {
        try {
            // Trigger the compilation first
            const compileResponse = await axios.post('https://tokeniza.co.uk/api/compileNFT');
            //http://tokeniza.co.uk/deploy

            console.log("compileResponse : " + compileResponse.data);

            if (compileResponse.data === 'Compilation successful' || compileResponse.data === 'Contracts are already compiled. Move to deployment') {
                console.log("Successfully compiled and now Deploying....");
                setResponse("Implementación y verificación….");

                console.log("chainID_CLIENT : " + _selectededNetwork);

                // If compilation is successful, trigger the deployment
                const deployResponse = await axios.post('https://tokeniza.co.uk/api/deployNFT', {
                    //http://tokeniza.co.uk/deploy

                    contractName: _ContractName,
                    series: _Series,
                    contractSymbol: _ContractSymbol,
                    totalSupply: _TotalSupply,
                    tokenTransferAddress: _TokenTransferAddress,
                    userAddress: userAddress,
                    otraInformacion: _OtraInformacion,
                    requisitosLegales: _RequisitosLegales,
                    getLastPaymentRecordIndex: Number(getLastPaymentRecordIndex),
                    selectededNetwork: _selectededNetwork,
                    baseuri: _baseuri,
                    notrevealeduri: _notrevealeduri,
                    contracturi: _contracturi,
                    givenMaxSupply: _givenMaxSupply
                });

                if (deployResponse.data === 'Deployment successful') {
                    setResponse('Contrato implementado y verificado exitosamente.');
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    navigate(`/nft-dashboard-sp`);
                } else {
                    setResponse(`Error durante la compilación: ${deployResponse.data}`);
                }
            } else {
                setResponse(`Error durante la compilación: ${compileResponse.data}`);
            }

        } catch (error) {
            console.error('Error during compilation and deployment:', error.message);
            setResponse(`Error durante la compilación y la implementación: ${error.message}`);
        }
    };*/}

    const handleCompileAndDeploy = async () => {
        try {
            // Trigger the compilation first
            const compileResponse = await axios.post('https://tokeniza.co.uk/api/compileNFT');

            console.log("compileResponse : " + compileResponse.data);
            console.log("Compiling...");

            if (compileResponse.data === 'Compilation successful' || compileResponse.data === 'Contracts are already compiled. Move to deployment') {
                console.log("Successfully compiled and now Deploying....");
                setResponse("Deploying & Verifying....");

                console.log("chainID_CLIENT : " + _selectededNetwork);

                // If compilation is successful, trigger the deployment

                // Check if _baseuri ends with "/"
                const baseuri = _baseuri.endsWith('/') ? _baseuri : _baseuri + '/';

                const deployResponse = await axios.post('https://tokeniza.co.uk/api/deployNFT', {
                    contractName: _ContractName,
                    series: _Series,
                    contractSymbol: _ContractSymbol,
                    totalSupply: _TotalSupply,
                    tokenTransferAddress: _TokenTransferAddress,
                    userAddress: userAddress,
                    otraInformacion: _OtraInformacion,
                    requisitosLegales: _RequisitosLegales,
                    getLastPaymentRecordIndex: Number(getLastPaymentRecordIndex),
                    selectededNetwork: _selectededNetwork,
                    baseuri: baseuri, // Use the modified baseuri
                    notrevealeduri: _notrevealeduri,
                    contracturi: _contracturi,
                    givenMaxSupply: _givenMaxSupply
                });

                if (deployResponse.data === 'Deployment successful') {
                    setResponse('Contract deployed & verified successfully.');
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    navigate(`/nft-dashboard`);
                } else {
                    setResponse(`Error during deployment: ${deployResponse.data}`);
                }
            } else {
                setResponse(`Error during compilation: ${compileResponse.data}`);
            }
        } catch (error) {
            console.error('Error during compilation and deployment:', error.message);
            setResponse(`Error during compilation and deployment: ${error.message}`);
        }
    };

    return (

        <div>
            <div class="header">Lanza y distribuye</div>
            <div class="registerT">Desbloquea el poder de la personalización y crea tu token único aquí, adaptándolo a tus preferencias y necesidades con solo unos pocos clics.</div>

            <div className='mainDiv'>

                <div>
                    <div>
                        <input
                            required
                            className="mintingAmount"
                            type="text"
                            name="_ContractName"
                            placeholder="Nombre del contrato"
                            value={_ContractName}
                            onChange={event => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 10) {
                                    set_ContractName(inputValue);
                                    setErrorMessage1(""); // Clear error message if length is valid
                                } else {
                                    setErrorMessage1("La longitud excede el máximo permitido (10 caracteres)"); // Set error message
                                }
                            }}
                        />
                        {/* Display error message if it's not empty */}
                        {errorMessage1 && <div style={{ color: 'red' }}>{errorMessage1}</div>}

                        <input
                            required
                            className="mintingAmount"
                            type="text"
                            name="_Series"
                            placeholder="Serie"
                            value={_Series}
                            onChange={event => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 10) {
                                    set_Series(inputValue);
                                    setErrorMessage2(""); // Clear error message if length is valid
                                } else {
                                    setErrorMessage2("La longitud excede el máximo permitido (10 caracteres)"); // Set error message
                                }
                            }}
                        />
                        {/* Display error message if it's not empty */}
                        {errorMessage2 && <div style={{ color: 'red' }}>{errorMessage2}</div>}

                        <input
                            required
                            className="mintingAmount"
                            type="text"
                            name="_ContractSymbol"
                            placeholder="Símbolo de Contrato"
                            value={_ContractSymbol}
                            onChange={event => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 10) {
                                    set_ContractSymbol(inputValue);
                                    setErrorMessage3(""); // Clear error message if length is valid
                                } else {
                                    setErrorMessage3("La longitud excede el máximo permitido (10 caracteres)"); // Set error message
                                }
                            }}
                        />
                        {/* Display error message if it's not empty */}
                        {errorMessage3 && <div style={{ color: 'red' }}>{errorMessage3}</div>}
                    </div>
                    <div>
                        <input
                            required
                            className="mintingAmount"
                            type="number"
                            name="_TotalSupply"
                            placeholder="Suministro Total"
                            value={_TotalSupply}
                            onChange={event => {
                                const inputValue = event.target.value;
                                if (inputValue <= 20000) {
                                    set_TotalSupply(inputValue);
                                    setErrorMessage5(""); // Clear error message if value is valid
                                } else {
                                    setErrorMessage5("El valor no debe exceder los 20.000"); // Set error message
                                }
                            }}
                        />
                        {/* Display error message if it's not empty */}
                        {errorMessage5 && <div style={{ color: 'red' }}>{errorMessage5}</div>}
                    </div>
                    <div>
                        <input
                            required
                            className="mintingAmount"
                            type="text"
                            name="_TokenTransferAddress"
                            placeholder="Dirección de Transferencia de Token"
                            value={_TokenTransferAddress}
                            onChange={event => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 42) {
                                    set_TokenTransferAddress(inputValue);
                                    setErrorMessage4(""); // Clear error message if length is valid
                                } else {
                                    setErrorMessage4("La longitud excede el máximo permitido (42 caracteres)"); // Set error message
                                }
                            }}
                        />
                        {/* Display error message if it's not empty */}
                        {errorMessage4 && <div style={{ color: 'red' }}>{errorMessage4}</div>}
                    </div>
                    <input required name="_OtraInformacion" placeholder='Otra información' value={_OtraInformacion}
                        onChange={event => set_OtraInformacion(event.target.value)}></input>

                    <label class="mintingAmount2" for="multilineInput">Requisitos legales</label>
                    <div></div>
                    <textarea id="multilineInput" name="inputText" rows="70" placeholder="Escribe tu texto aquí..." value={_RequisitosLegales}
                        onChange={event => set_RequisitosLegales(event.target.value)}></textarea>

                    <input required name="_baseuri" placeholder='URI base' value={_baseuri}
                        onChange={event => set_baseuri(event.target.value)}></input>

                    <input required name="_notrevealeduri" placeholder='URI no revelada' value={_notrevealeduri}
                        onChange={event => set_notrevealeduri(event.target.value)}></input>

                    <input required name="_contracturi" placeholder='URI de contrato' value={_contracturi}
                        onChange={event => set_contracturi(event.target.value)}></input>

                    <div>
                        <input required
                            name="givenMaxSupply"
                            placeholder="Suministro máximo"
                            value={_givenMaxSupply}
                            onChange={event => {
                                const inputValue = event.target.value;
                                if (!isNaN(inputValue) && parseInt(inputValue) <= 20000) {
                                    set_givenMaxSupply(inputValue);
                                    setErrorMessage6(""); // Clear error message if value is valid
                                } else {
                                    setErrorMessage6("El valor no debe exceder los 20.000"); // Set error message
                                }
                            }}
                        />
                        {/* Display error message if it's not empty */}
                        {errorMessage6 && <div style={{ color: 'red' }}>{errorMessage6}</div>}
                    </div>
                </div>

                {/*<button class='inButton' onClick={handleCompileAndDeploy}>Compila y distribuye tu Smart Contract</button>*/}
                <button className='inButton' onClick={handleCompileAndDeploy} disabled={isButtonDisabled}>
                    Compila y distribuye tu Smart Contract
                </button>
                <div className='note'>{response}</div>

            </div>
        </div >
    );
};

export default CompileAndDeployComponent;
