import React, { useState, useEffect } from "react";
import { ethers, utils } from 'ethers';
import '../pages/main.css';
import { paymentContractAddressPolygonNFT } from '../config';
import { paymentContractAddressEthNFT } from '../config';
import { paymentContractAddressAvax } from '../config';
import { paymentContractAddressBnbNFT } from '../config';
import { paymentContractAddressFtmNFT } from '../config';
import { paymentContractAddressArbNFT } from '../config';
import { paymentContractAddressOpNFT } from '../config';
import { paymentContractAddressBaseNFT } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import Web3Modal from "web3modal";
import axios from 'axios';
import Footer from "../components/footer";
import eth from '../images/eth.png';
import matic from '../images/matic.png';
import avax from '../images/avax.png';
import bnb from '../images/bnb.png';
import ftm from '../images/ftm.png';
import arb from '../images/arb.png';
import op from '../images/op.png';
import base from '../images/base.png';
import link from '../images/link.png';
import view from '../images/view.png';

import { Button, useNotification, Loading } from '@web3uikit/core';

const NFTDashboardSP = () => {
    const [deployments, setDeployments] = useState([]);
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();
    const [response, setResponse] = useState('');
    const [revealedLC, setRevealedLC] = useState('');
    const [_details, setDetails] = useState('');
    const [closeDiv, setCloseDiv] = useState(0);
    const [closeDiv2, setCloseDiv2] = useState(0);
    const [_navbarOpen, set_navbarOpen] = useState(0)
    const [_chainName, setChainName] = useState();
    const [_rpcUrls, set_rpcUrls] = useState();
    const [_blockExpolrerUrls, set_blockExplorerURL] = useState();
    const [_decimals, set_decimals] = useState();
    const [_symbol, set_symbol] = useState();
    const [_selectedChain, set_chain] = useState();
    const [contractPayment, setcontract] = useState('');
    const [_connectPressed, set_connectPressed] = useState(0);
    const [_displayChain, set_displayChain] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const notification = useNotification();
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [_maxSupply, setMaxSupply] = useState(0);
    const [_creator, setCreator] = useState(0);
    const [_collectionName, setCollectionName] = useState(0);
    const [_collectionSymbol, setCollectionSymbol] = useState(0);
    const [_max_per_wallet, setMax_per_wallet] = useState(0);
    const [_notRevealedUri, setNotRevealedUri] = useState(0);
    const [_publicSaleCost, setPublicSaleCost] = useState(0);
    const [_public_mint_status, setPublic_mint_status] = useState(0);
    const [_owner, setOwner] = useState(0);
    const [_totalSupply, set_totalSupply] = useState(0);

    const [_currencyType, setCurrencyType] = useState();
    const [_currentContractAddress, setCurrentContractAddress] = useState("");
    const [buttonClicked, setButtonClicked] = useState(false);

    const writeL1 = () => {
        let url = '';
        switch (selectedImage) {
            case 'eth':
                url = `https://etherscan.io/address/${_currentContractAddress}#writeContract#F12`;
                break;
            case 'matic':
                url = `https://polygonscan.com/address/${_currentContractAddress}#writeContract#F12`;
                break;
            case 'bnb':
                url = `https://bscscan.com/address/${_currentContractAddress}#writeContract#F12`;
                break;
            case 'op':
                url = `https://optimistic.etherscan.io/address/${_currentContractAddress}#writeContract#F12`;
                break;
            case 'avax':
                url = `https://snowtrace.io/address/${_currentContractAddress}#writeContract#F12`;
                break;
            case 'ftm':
                url = `https://ftmscan.com/address/${_currentContractAddress}#writeContract#F12`;
                break;
            case 'arb':
                url = `https://arbiscan.io/address/${_currentContractAddress}#writeContract#F12`;
                break;
            case 'base':
                url = `https://basescan.org/address/${_currentContractAddress}#writeContract#F12`;
                break;
            default:
                console.log("Selected network not recognized");
        }
        if (url !== '') {
            window.open(url);
        }
    }


    const writeL2 = () => {
        let url = '';
        switch (selectedImage) {
            case 'eth':
                url = `https://etherscan.io/address/${_currentContractAddress}#writeContract#F20`;
                break;
            case 'matic':
                url = `https://polygonscan.com/address/${_currentContractAddress}#writeContract#F20`;
                break;
            case 'bnb':
                url = `https://bscscan.com/address/${_currentContractAddress}#writeContract#F20`;
                break;
            case 'op':
                url = `https://optimistic.etherscan.io/address/${_currentContractAddress}#writeContract#F20`;
                break;
            case 'avax':
                url = `https://snowtrace.io/address/${_currentContractAddress}#writeContract#F20`;
                break;
            case 'ftm':
                url = `https://ftmscan.com/address/${_currentContractAddress}#writeContract#F20`;
                break;
            case 'arb':
                url = `https://arbiscan.io/address/${_currentContractAddress}#writeContract#F20`;
                break;
            case 'base':
                url = `https://basescan.org/address/${_currentContractAddress}#writeContract#F20`;
                break;
            default:
                console.log("Selected network not recognized");
        }
        if (url !== '') {
            window.open(url);
        }
    }


    const writeL3 = () => {
        let url = '';
        switch (selectedImage) {
            case 'eth':
                url = `https://etherscan.io/address/${_currentContractAddress}#writeContract#F13`;
                break;
            case 'matic':
                url = `https://polygonscan.com/address/${_currentContractAddress}#writeContract#F13`;
                break;
            case 'bnb':
                url = `https://bscscan.com/address/${_currentContractAddress}#writeContract#F13`;
                break;
            case 'op':
                url = `https://optimistic.etherscan.io/address/${_currentContractAddress}#writeContract#F13`;
                break;
            case 'avax':
                url = `https://snowtrace.io/address/${_currentContractAddress}#writeContract#F13`;
                break;
            case 'ftm':
                url = `https://ftmscan.com/address/${_currentContractAddress}#writeContract#F13`;
                break;
            case 'arb':
                url = `https://arbiscan.io/address/${_currentContractAddress}#writeContract#F13`;
                break;
            case 'base':
                url = `https://basescan.org/address/${_currentContractAddress}#writeContract#F13`;
                break;
            default:
                console.log("Selected network not recognized");
        }
        if (url !== '') {
            window.open(url);
        }
    }


    const writeL4 = () => {
        let url = '';
        switch (selectedImage) {
            case 'eth':
                url = `https://etherscan.io/address/${_currentContractAddress}#writeContract#F15`;
                break;
            case 'matic':
                url = `https://polygonscan.com/address/${_currentContractAddress}#writeContract#F15`;
                break;
            case 'bnb':
                url = `https://bscscan.com/address/${_currentContractAddress}#writeContract#F15`;
                break;
            case 'op':
                url = `https://optimistic.etherscan.io/address/${_currentContractAddress}#writeContract#F15`;
                break;
            case 'avax':
                url = `https://snowtrace.io/address/${_currentContractAddress}#writeContract#F15`;
                break;
            case 'ftm':
                url = `https://ftmscan.com/address/${_currentContractAddress}#writeContract#F15`;
                break;
            case 'arb':
                url = `https://arbiscan.io/address/${_currentContractAddress}#writeContract#F15`;
                break;
            case 'base':
                url = `https://basescan.org/address/${_currentContractAddress}#writeContract#F15`;
                break;
            default:
                console.log("Selected network not recognized");
        }
        if (url !== '') {
            window.open(url);
        }
    }


    const writeL5 = () => {
        let url = '';
        switch (selectedImage) {
            case 'eth':
                url = `https://etherscan.io/address/${_currentContractAddress}#writeContract#F18`;
                break;
            case 'matic':
                url = `https://polygonscan.com/address/${_currentContractAddress}#writeContract#F18`;
                break;
            case 'bnb':
                url = `https://bscscan.com/address/${_currentContractAddress}#writeContract#F18`;
                break;
            case 'op':
                url = `https://optimistic.etherscan.io/address/${_currentContractAddress}#writeContract#F18`;
                break;
            case 'avax':
                url = `https://snowtrace.io/address/${_currentContractAddress}#writeContract#F18`;
                break;
            case 'ftm':
                url = `https://ftmscan.com/address/${_currentContractAddress}#writeContract#F18`;
                break;
            case 'arb':
                url = `https://arbiscan.io/address/${_currentContractAddress}#writeContract#F18`;
                break;
            case 'base':
                url = `https://basescan.org/address/${_currentContractAddress}#writeContract#F18`;
                break;
            default:
                console.log("Selected network not recognized");
        }
        if (url !== '') {
            window.open(url);
        }
    }

    const warningNotification = () => {
        notification({
            type: 'warning',
            message: 'Change network to Polygon Mumbai to visit this site',
            title: 'Switch to Polygon Mumbai Test Network',
            position: 'topR'
        });
    };

    const infoNotification = (accountNum) => {
        notification({
            type: 'info',
            message: accountNum,
            title: `Connected to ${_chainName}`,
            position: 'topR'
        });
    };

    useEffect(() => {

        if (!provider) {
            // window.open("https://metamask.app.link/dapp/kudeta.io/");
            //window.location.replace("https://metamask.io");

            // Render something or redirect as needed
            console.log("provider :" + provider);
        } else {

            const handleAccountsChanged = (accounts) => {
                if (provider.chainId == _selectedChain) {
                    infoNotification(accounts[0]);
                }
                // Just to prevent reloading for the very first time
                /*if (JSON.parse(localStorage.getItem('activeAccount')) != null) {
                  setTimeout(() => { window.location.reload() }, 3000);
                }*/
            };

            const handleChainChanged = (chainId) => {
                const hexChainId = utils.hexValue(_selectedChain); // "0x01"

                console.log("chainId :" + chainId);
                console.log("hexChainId :" + hexChainId);

                if (chainId != hexChainId) {


                    window.location.reload();
                }

            }

            const handleDisconnect = () => {
            };

            provider.on("accountsChanged", handleAccountsChanged);
            provider.on("chainChanged", handleChainChanged);
            provider.on("disconnect", handleDisconnect);

            selectedNetwork();

        }

    }, []);

    const disconnect = async () => {
    }

    const checkEligibility = async () => {

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);

            //  let provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const signerAddress = await signer.getAddress();
            setSignerAddress(signerAddress);

            const getnetwork = await provider.getNetwork();
            console.log("getnetwork :" + getnetwork.chainId);


            if (getnetwork.chainId == "1") {

                // setcontract(paymentContractAddressEthNFT);
                const contract = new ethers.Contract(paymentContractAddressEthNFT, TwitterAbi.abi, signer);
                const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
                setIsEligibleToDeploy(isEligibleToDeploy);
                console.log("isEligibleToDeploy NFT - sp : " + isEligibleToDeploy);
                set_chain(1);
                setCurrencyType('ETH');

            }

            if (getnetwork.chainId == "137") {

                //   setcontractsetcontract(paymentContractAddressPolygonNFT);
                const contract = new ethers.Contract(paymentContractAddressPolygonNFT, TwitterAbi.newABI2, signer);
                const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
                setIsEligibleToDeploy(isEligibleToDeploy);
                console.log("isEligibleToDeploy NFT - sp : " + isEligibleToDeploy);
                set_chain(137);
                setCurrencyType('MATIC');


            } else {

                console.log("--------")
            }

            //BNB
            if (getnetwork.chainId == "56") {

                //  setcontract(paymentContractAddressBnbNFT);
                const contract = new ethers.Contract(paymentContractAddressBnbNFT, TwitterAbi.abi, signer);
                const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
                setIsEligibleToDeploy(isEligibleToDeploy);
                set_chain(56);
                setCurrencyType('BNB');

            }

            //OP
            if (getnetwork.chainId == "10") {

                // setcontract(paymentContractAddressOpNFT);
                const contract = new ethers.Contract(paymentContractAddressOpNFT, TwitterAbi.abi, signer);
                const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
                setIsEligibleToDeploy(isEligibleToDeploy);
                set_chain(10);
                setCurrencyType('ETH');


            }

            //AVAX
            if (getnetwork.chainId == "43114") {

                // setcontract(paymentContractAddressAvax);
                const contract = new ethers.Contract(paymentContractAddressAvax, TwitterAbi.abi, signer);
                const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
                setIsEligibleToDeploy(isEligibleToDeploy);
                set_chain(43114);
                setCurrencyType('AVAX');

            }

            //FTM
            if (getnetwork.chainId == "250") {

                //  setcontract(paymentContractAddressFtmNFT);
                const contract = new ethers.Contract(paymentContractAddressFtmNFT, TwitterAbi.abi, signer);
                const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
                setIsEligibleToDeploy(isEligibleToDeploy);
                set_chain(250);
                setCurrencyType('FTM');

            }

            //ARB
            if (getnetwork.chainId == "42161") {

                // setcontract(paymentContractAddressArbNFT);
                const contract = new ethers.Contract(paymentContractAddressArbNFT, TwitterAbi.abi, signer);
                const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
                setIsEligibleToDeploy(isEligibleToDeploy);
                set_chain(42161);
                setCurrencyType('ETH');

            }

            //BASE
            if (getnetwork.chainId == "8453") {

                // setcontract(paymentContractAddressBaseNFT);
                const contract = new ethers.Contract(paymentContractAddressBaseNFT, TwitterAbi.abi, signer);
                const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
                setIsEligibleToDeploy(isEligibleToDeploy);
                set_chain(8453);
                setCurrencyType('ETH');

            }

            console.log("EligibilitycontractPayment : " + contractPayment);

        } catch (err) {
            console.log(err);
        }
    }

    const getLastPaymentRecordIndex = async () => {

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);
            //  let provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const signerAddress = await signer.getAddress();
            setSignerAddress(signerAddress);

            const getnetwork = await provider.getNetwork();
            console.log("getnetwork :" + getnetwork.chainId);


            //ETH
            if (getnetwork.chainId == "1") {

                setcontract(paymentContractAddressEthNFT);

                // console.log("provider.getCode(address) : " + provider.getCode(signerAddress) );
                const contract = new ethers.Contract(paymentContractAddressEthNFT, TwitterAbi.abi, signer);

                const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
                console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
                setLastPaymentRecordIndex(getLastPaymentRecordIndex);

            }

            //POLYGON
            if (getnetwork.chainId == "137") {

                setcontract(paymentContractAddressPolygonNFT);

                // console.log("provider.getCode(address) : " + provider.getCode(signerAddress) );
                const contract = new ethers.Contract(paymentContractAddressPolygonNFT, TwitterAbi.newABI2, signer);
                console.log("getLastPaymentRecordIndex : " + paymentContractAddressPolygonNFT);

                const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
                console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
                setLastPaymentRecordIndex(getLastPaymentRecordIndex);

            } else {

                console.log("--------")
            }

            //BNB
            if (getnetwork.chainId == "56") {

                setcontract(paymentContractAddressBnbNFT);
                const contract = new ethers.Contract(paymentContractAddressBnbNFT, TwitterAbi.abi, signer);

                const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
                console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
                setLastPaymentRecordIndex(getLastPaymentRecordIndex);

            }

            //OP
            if (getnetwork.chainId == "10") {

                setcontract(paymentContractAddressOpNFT);
                const contract = new ethers.Contract(paymentContractAddressOpNFT, TwitterAbi.abi, signer);

                const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
                console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
                setLastPaymentRecordIndex(getLastPaymentRecordIndex);
            }

            //AVAX
            if (getnetwork.chainId == "43114") {

                setcontract(paymentContractAddressAvax);
                const contract = new ethers.Contract(paymentContractAddressAvax, TwitterAbi.abi, signer);

                const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
                console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
                setLastPaymentRecordIndex(getLastPaymentRecordIndex);
            }

            //FTM
            if (getnetwork.chainId == "250") {

                setcontract(paymentContractAddressFtmNFT);
                const contract = new ethers.Contract(paymentContractAddressFtmNFT, TwitterAbi.abi, signer);

                const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
                console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
                setLastPaymentRecordIndex(getLastPaymentRecordIndex);
            }

            //ARB
            if (getnetwork.chainId == "42161") {

                setcontract(paymentContractAddressArbNFT);
                const contract = new ethers.Contract(paymentContractAddressArbNFT, TwitterAbi.abi, signer);

                const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
                console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
                setLastPaymentRecordIndex(getLastPaymentRecordIndex);
            }

            //BASE
            if (getnetwork.chainId == "8453") {

                setcontract(paymentContractAddressBaseNFT);
                const contract = new ethers.Contract(paymentContractAddressBaseNFT, TwitterAbi.abi, signer);

                const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
                console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
                setLastPaymentRecordIndex(getLastPaymentRecordIndex);
            }

        } catch (err) {
            console.log(err);
        }
    }

    const connectWallet = async () => {
        set_connectPressed(1);
        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = _selectedChain;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(chainID),
                                            chainName: _chainName,
                                            rpcUrls: [_rpcUrls],
                                            blockExpolrerUrls: [_blockExpolrerUrls],
                                            nativeCurrency:
                                            {
                                                decimals: _decimals,
                                                symbol: _symbol,
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEthNFT);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygonNFT);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnbNFT);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOpNFT);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtmNFT);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArbNFT);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBaseNFT);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    const connectBtn = async () => {
        checkEligibility();
        getLastPaymentRecordIndex();

        set_connectPressed(1);
    }

    const handleImageClick = (imageName) => {
        setSelectedImage(imageName);
        // ... (rest of your logic)
    };

    async function selectedNetwork() {

        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();

        let provider = new ethers.providers.Web3Provider(connection);

        const signer = provider.getSigner();
        const signerAddress = await signer.getAddress();
        setSignerAddress(signerAddress);
        console.log("ELSEconnectWallet();");

        const getnetwork = await provider.getNetwork();
        console.log("getnetwork :" + getnetwork);

        if (getnetwork.chainId == "1") {

            setSelectedImage('eth');

        }

        if (getnetwork.chainId == "137") {

            setSelectedImage('matic');

        } else {

            console.log("--------")
        }


        if (getnetwork.chainId == "56") {

            setSelectedImage('bnb');

        }

        if (getnetwork.chainId == "10") {

            setSelectedImage('op');

        }

        if (getnetwork.chainId == "43114") {

            setSelectedImage('avax');

        }

        if (getnetwork.chainId == "250") {

            setSelectedImage('ftm');

        }

        if (getnetwork.chainId == "42161") {

            setSelectedImage('arb');

        }

        if (getnetwork.chainId == "8453") {

            setSelectedImage('base');

        }

        //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

        setIsAuthenticated(true);
        setProvider(provider);

    }

    async function setChainETH(imageName) {

        setSelectedImage(imageName);


        /* set_chain(1);
         setcontract(paymentContractAddressEthNFT);
         setChainName('Ethereum Mainnet');
         set_symbol('ETH');
         set_rpcUrls('https://mainnet.infura.io/v3/');
         set_blockExplorerURL('https://etherscan.io');*/

        /*set_chain(1);
        setChainName('Goerli test network');
        set_symbol('ETH');
        set_rpcUrls('https://goerli.infura.io/v3/');
        set_blockExplorerURL('https://goerli.etherscan.io');*/
        console.log("Eth chain");

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");


            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 1;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(1),
                                            chainName: 'Goerli test network',
                                            rpcUrls: ['https://goerli.infura.io/v3/'],
                                            blockExpolrerUrls: ['https://goerli.etherscan.io'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'ETH',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const web3Modal = new Web3Modal();
                    const connection = await web3Modal.connect();
                    let provider = new ethers.providers.Web3Provider(connection);
                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEthNFT);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygonNFT);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnbNFT);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOpNFT);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtmNFT);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArbNFT);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBaseNFT);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    async function setChainPOLY(imageName) {

        setSelectedImage(imageName);

        //set_chain(137/*137*/);
        //setChainName('Mumbai');
        //set_symbol('Matic');
        /*set_rpcUrls('https://polygon-mainnet.infura.io');
        set_blockExplorerURL('https://polygonscan.com/');*/
        //set_rpcUrls('https://rpc-mumbai.maticvigil.com/v1/a3a160abeb239e773a4525d267cb84f3660634ed');
        //set_blockExplorerURL('https://mumbai.polygonscan.com');
        //console.log("setChainPOLY");

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 137;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(137),
                                            chainName: 'Polygon LlamaNodes',
                                            rpcUrls: ['https://polygon-mainnet.infura.io/v3/32edc413e8e247f3acc67e539f70d74a'],
                                            blockExpolrerUrls: ['https://polygonscan.com'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'Matic',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEthNFT);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygonNFT);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnbNFT);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOpNFT);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtmNFT);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArbNFT);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBaseNFT);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    async function setChainBNB(imageName) {
        setSelectedImage(imageName);

        /*set_chain(56);
            setChainName('BNB Smart Chain Mainnet');
            set_symbol('BNB');
            set_rpcUrls('https://bsc-dataseed.binance.org/');
            set_blockExplorerURL('https://bscscan.com/');
            console.log("setChainBNB");*/

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 56;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(56),
                                            chainName: 'BNB Chain',
                                            rpcUrls: ['https://bsc-dataseed.binance.org/'],
                                            blockExpolrerUrls: ['https://bscscan.com/'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'BNB',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEthNFT);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygonNFT);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnbNFT);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOpNFT);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtmNFT);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArbNFT);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBaseNFT);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    async function setChainOP(imageName) {
        setSelectedImage(imageName);

        /*set_chain(10);
        setChainName('OP Mainnet');
        set_symbol('ETH');
        set_rpcUrls('https://mainnet.optimism.io');
        set_blockExplorerURL('https://optimistic.etherscan.io');
        console.log("setChainOP");*/

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 10;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(10),
                                            chainName: 'OP Mainnet',
                                            rpcUrls: ['https://mainnet.optimism.io'],
                                            blockExpolrerUrls: ['https://optimistic.etherscan.io'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'ETH',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEthNFT);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygonNFT);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnbNFT);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOpNFT);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtmNFT);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArbNFT);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBaseNFT);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    async function setChainAVAX(imageName) {
        setSelectedImage(imageName);

        /*set_chain(43114);
        setChainName('Avalanche C-Chain');
        set_symbol('AVAX');
        set_rpcUrls('https://api.avax.network/ext/bc/C/rpc');
        set_blockExplorerURL('https://snowtrace.io');
        console.log("setChainAVAX");*/

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 43114;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(43114),
                                            chainName: 'Avalanche C-Chain',
                                            rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
                                            blockExpolrerUrls: ['https://snowtrace.io'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'Avalanche C-Chain',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEthNFT);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygonNFT);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnbNFT);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOpNFT);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtmNFT);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArbNFT);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBaseNFT);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    async function setChainFTM(imageName) {
        setSelectedImage(imageName);

        /*set_chain(250);
        setChainName('Fantom Opera');
        set_symbol('FTM');
        set_rpcUrls('https://rpcapi.fantom.network');
        set_blockExplorerURL('https://ftmscan.com');
        console.log("setChainFTM");*/

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 250;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(250),
                                            chainName: 'Fantom Testnet',
                                            rpcUrls: ['https://rpc.testnet.fantom.network'],
                                            blockExpolrerUrls: ['https://testnet.ftmscan.com'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'FTM',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEthNFT);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygonNFT);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnbNFT);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOpNFT);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtmNFT);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArbNFT);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBaseNFT);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    async function setChainARB(imageName) {
        setSelectedImage(imageName);

        /*set_chain(42161);
        setChainName('Arbitrum One Mainnet');
        set_symbol('ETH');
        set_rpcUrls('https://arb1.arbitrum.io/rpc');
        set_blockExplorerURL('https://arbiscan.io/');
        console.log("setChainARB");*/

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 42161;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(42161),
                                            chainName: 'Arbitrum One Mainnet',
                                            rpcUrls: ['https://arb1.arbitrum.io/rpc'],
                                            blockExpolrerUrls: ['https://arbiscan.io/'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'ETH',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEthNFT);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygonNFT);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnbNFT);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOpNFT);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtmNFT);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArbNFT);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBaseNFT);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    async function setChainBASE(imageName) {
        setSelectedImage(imageName);

        /*set_chain(8453);
        setChainName('Base');
        set_symbol('ETH');
        set_rpcUrls('https://developer-access-mainnet.base.org');
        set_blockExplorerURL('https://basescan.org');
        console.log("setChainBASE");*/

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 8453;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(8453),
                                            chainName: 'Base',
                                            rpcUrls: ['https://developer-access-mainnet.base.org'],
                                            blockExpolrerUrls: ['https://basescan.org'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'ETH',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEthNFT);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygonNFT);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnbNFT);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOpNFT);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtmNFT);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArbNFT);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBaseNFT);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }



    async function verify(contractAddress) {
        try {
            console.log("contractAddress : " + contractAddress);
            const verifyResponse = await axios.post('http://localhost:3001/verify',
                {
                    contractAddress: contractAddress
                });

            if (verifyResponse.data == 'Verified successfully') {
                setResponse('Contract Verified successfully.');
            } else {
                setResponse('Contract Verified Failed.');

            }


        } catch (err) {
            console.log(err)
        }
    }

    async function lcReveal(reveal) {
        try {

            setRevealedLC(reveal);
            setCloseDiv(1);
        } catch (err) {
            console.log(err)
        }
    }

    async function details(contractAddress) {
        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);
            const signer = provider.getSigner();
            const signerAddress = await signer.getAddress();
            setSignerAddress(signerAddress);

            setButtonClicked(true);
            setCurrentContractAddress(contractAddress);
            const contract = new ethers.Contract(contractAddress, TwitterAbi.userABINFT, signer);

            const maxSupply = await contract.MAX_SUPPLY();
            setMaxSupply(Number(maxSupply));

            const creator = await contract._creator();
            setCreator(creator);

            const collectionName = await contract.collectionName();
            setCollectionName(collectionName);

            const collectionSymbol = await contract.collectionSymbol();
            setCollectionSymbol(collectionSymbol);

            const max_per_wallet = await contract.max_per_wallet();
            setMax_per_wallet(Number(max_per_wallet));

            const notRevealedUri = await contract.notRevealedUri();
            setNotRevealedUri(notRevealedUri);

            const publicSaleCost = await contract.publicSaleCost();
            setPublicSaleCost(Number(publicSaleCost));

            const public_mint_status = await contract.public_mint_status();
            setPublic_mint_status(public_mint_status.toString());

            const owner = await contract.owner();
            setOwner(owner);

            const totalSupply = await contract.totalSupply();
            set_totalSupply(Number(totalSupply));

            // Call the openDiv function after setting maxSupply
            openDiv();

        } catch (err) {
            console.log(err)
        }
    }

    async function viewNfts(contractAddress) {
        try {

            const options = {
                method: 'GET',
                headers: { accept: 'application/json', 'x-api-key': '02b5d0bc34aa42edacd4222f450023a5' }
            };

            fetch(`https://api.opensea.io/api/v2/chain/matic/contract/${contractAddress}/nfts`, options)
                .then(response => response.json())
                .then(response => {
                    const modalContainer = document.getElementById('modalContainer');
                    const imageContainer = document.getElementById('imageContainer');

                    // Clear previous content
                    imageContainer.innerHTML = '';

                    // Iterate over the response.nfts array and create img elements
                    response.nfts.forEach(nft => {
                        if (nft.image_url) {
                            const imgContainer = document.createElement('div');
                            imgContainer.classList.add('nft-item');

                            const img = document.createElement('img');
                            img.src = nft.image_url;
                            img.alt = nft.identifier ? `NFT ${nft.identifier}` : 'NFT Image';

                            const identifier = document.createElement('span');
                            identifier.textContent = `#${nft.identifier}`;
                            identifier.classList.add('identifier');

                            imgContainer.appendChild(img);
                            imgContainer.appendChild(identifier);
                            imageContainer.appendChild(imgContainer);
                        }
                    });

                    // Show the modal
                    modalContainer.style.display = 'block';
                })
                .catch(err => console.error(err));

            // Close the modal when the close button is clicked
            document.querySelector('.close').addEventListener('click', function () {
                document.getElementById('modalContainer').style.display = 'none';
            });

            await new Promise(resolve => setTimeout(resolve, 5000));

            // nftopenDiv();

        } catch (err) {
            console.log(err)
        }
    }

    async function openDiv() {
        // After setting the state, _maxSupply should be accessible

        // If you need to use _maxSupply somewhere, you can use it here
        setCloseDiv2(1);
    }


    async function close() {
        try {

            setCloseDiv(0);
            setCloseDiv2(0);

            setButtonClicked(false);

        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        // Function to fetch deployment data
        const getAllPayments = async () => {
            try {

                const web3Modal = new Web3Modal();
                const connection = await web3Modal.connect();
                const provider = new ethers.providers.Web3Provider(connection);
                //  let provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const signerAddress = await signer.getAddress();
                setSignerAddress(signerAddress);

                const getnetwork = await provider.getNetwork();
                console.log("getnetwork :" + getnetwork.chainId);
                setChainName(getnetwork.chainId);


                if (getnetwork.chainId == "1") {

                    const contract = new ethers.Contract(paymentContractAddressEthNFT, TwitterAbi.abi, signer);
                    const userPayments = await contract.getAllPayments();
                    console.log("userPayments :" + userPayments[0].timeDeployed);
                    setDeployments(userPayments);


                }

                if (getnetwork.chainId == "137") {

                    const contract = new ethers.Contract(paymentContractAddressPolygonNFT, TwitterAbi.newABI2, signer);
                    const userPayments = await contract.getAllPayments();
                    console.log("userPayments :" + userPayments[0].timeDeployed);
                    setDeployments(userPayments);


                }

                if (getnetwork.chainId == "56") {

                    const contract = new ethers.Contract(paymentContractAddressBnbNFT, TwitterAbi.abi, signer);
                    const userPayments = await contract.getAllPayments();
                    console.log("userPayments :" + userPayments[0].timeDeployed);
                    setDeployments(userPayments);


                }

                if (getnetwork.chainId == "10") {

                    const contract = new ethers.Contract(paymentContractAddressOpNFT, TwitterAbi.abi, signer);
                    const userPayments = await contract.getAllPayments();
                    console.log("userPayments :" + userPayments[0].timeDeployed);
                    setDeployments(userPayments);


                }

                if (getnetwork.chainId == "43114") {

                    const contract = new ethers.Contract(paymentContractAddressAvax, TwitterAbi.abi, signer);
                    const userPayments = await contract.getAllPayments();
                    console.log("userPayments :" + userPayments[0].timeDeployed);
                    setDeployments(userPayments);


                }

                if (getnetwork.chainId == "250") {

                    const contract = new ethers.Contract(paymentContractAddressFtmNFT, TwitterAbi.abi, signer);
                    const userPayments = await contract.getAllPayments();
                    console.log("userPayments :" + userPayments[0].timeDeployed);
                    setDeployments(userPayments);

                }

                if (getnetwork.chainId == "42161") {

                    const contract = new ethers.Contract(paymentContractAddressArbNFT, TwitterAbi.abi, signer);
                    const userPayments = await contract.getAllPayments();
                    console.log("userPayments :" + userPayments[0].timeDeployed);
                    setDeployments(userPayments);


                }

                if (getnetwork.chainId == "8453") {

                    const contract = new ethers.Contract(paymentContractAddressBaseNFT, TwitterAbi.abi, signer);
                    const userPayments = await contract.getAllPayments();
                    console.log("userPayments :" + userPayments[0].timeDeployed);
                    setDeployments(userPayments);


                }

                /*   const result = await Promise.all(userPayments.map(async (d,index) => {
   
                   let item = {
                       companyId: d[0].companyId,
                   };
   
                   console.log("contractName : " + item.contractName);
                   return item;
   
               }));
   
               setDeployments(result.reverse());
   */

            } catch (error) {
                console.error("Error fetching deployments:", error);
            }
        };
        // Call the function to fetch data when the component mounts
        getAllPayments();
    }, []); // Empty dependency array to run the effect only once when the component mounts

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }

    return (
        <div class="dashMain">
            <div class="light2">

                <div class="headers2">

                    <div class="h2">
                        <div class="logoDiv">
                            <span class="dashH" onClick={() => window.location.href = 'nft'}>Tokeniza</span>
                        </div>

                        <button class="connectBtn" onClick={() => window.location.href = 'nft-sp'}>HOGAR</button>

                    </div>

                </div>

                <div class="connectNetworks2">
                    <div class="network">
                        {/*<img
                            onClick={() => setChainETH('eth')}
                            src={eth}
                            className={selectedImage === 'eth' ? 'selected' : ''}

    />*/}
                        <img
                            onClick={() => setChainPOLY('matic')}
                            src={matic}
                            className={selectedImage === 'matic' ? 'selected' : ''}
                        />
                        <img
                            onClick={() => setChainAVAX('avax')}
                            src={avax}
                            className={selectedImage === 'avax' ? 'selected' : ''}
                        />
                        <img
                            onClick={() => setChainBNB('bnb')}
                            src={bnb}
                            className={selectedImage === 'bnb' ? 'selected' : ''}
                        />
                        <img
                            onClick={() => setChainFTM('ftm')}
                            src={ftm}
                            className={selectedImage === 'ftm' ? 'selected' : ''}
                        />
                        <img
                            onClick={() => setChainARB('arb')}
                            src={arb}
                            className={selectedImage === 'arb' ? 'selected' : ''}
                        />
                        <img
                            onClick={() => setChainOP('op')}
                            src={op}
                            className={selectedImage === 'op' ? 'selected' : ''}
                        />
                        <img
                            onClick={() => setChainBASE('base')}
                            src={base}
                            className={selectedImage === 'base' ? 'selected' : ''}
                        />
                    </div>
                    <p class="registerT" data-aos="fade-up" data-aos-duration="500"><span class="spanR">⦁	Elige una red, haz clic en 'Conectar' y observa todas tus implementaciones. ¡Es así de fácil!</span></p>
                    {_connectPressed > 0 ?
                        (<button class="connectReg">Conectado</button>) :
                        (<button class="connectReg" onClick={connectBtn}>Conectar</button>)
                    }
                </div>

                {(_connectPressed > 0) ? (
                    <div>
                        {deployments.length > 0 ? (
                            <div className="mainContent">
                                <main>
                                    <section>
                                        <div className='dashT'>¡Bienvenido a tu tablero de control! </div>
                                        <p className='dashP'>¡Explore todos los detalles complejos de su implementación aquí mismo!</p>
                                    </section>

                                    <section>
                                        <div className='dashT2'>Tus lanzamientos</div>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Fecha</th>
                                                    <th>Número de empresa</th>
                                                    <th>Nombre de la empresa</th>
                                                    <th>Dirección del contrato</th>
                                                    <th>Requisitos legales</th>
                                                    <th>Detalles</th>
                                                    <th>Ver NFTs</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {deployments.map((deployment, index) => (
                                                    deployment.contractAddress != '0x0000000000000000000000000000000000000000' ? (
                                                    <tr key={index}>
                                                        <td>{new Date(deployment.timeDeployed * 1000).toLocaleString()}</td>
                                                        <td>{deployment.companyId}</td>
                                                        <td>{deployment.companyName}</td>
                                                        <td id='font'>
                                                            <a
                                                                href={
                                                                    selectedImage === 'eth' ? `https://etherscan.io/address/${deployment.contractAddress}` :
                                                                        selectedImage === 'matic' ? `https://polygonscan.com/address/${deployment.contractAddress}` :
                                                                            selectedImage === 'bnb' ? `https://bscscan.com/address/${deployment.contractAddress}` :
                                                                                selectedImage === 'op' ? `https://optimistic.etherscan.io/address/${deployment.contractAddress}` :
                                                                                    selectedImage === 'avax' ? `https://snowtrace.io/address/${deployment.contractAddress}` :
                                                                                        selectedImage === 'ftm' ? `https://ftmscan.com/address/${deployment.contractAddress}` :
                                                                                            selectedImage === 'arb' ? `https://arbiscan.io/address/${deployment.contractAddress}` :
                                                                                                selectedImage === 'base' ? `https://basescan.org/address/${deployment.contractAddress}` :
                                                                                                    ''
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {deployment.contractAddress === '0x0000000000000000000000000000000000000000' ? (
                                                                    <div>Loading... <br />(Please refresh in 1 min)</div>
                                                                ) : (
                                                                    <div>{deployment.contractAddress}</div>
                                                                )}
                                                            </a>

                                                        </td>
                                                        <td><button className="verify" onClick={() => lcReveal(deployment.legalDocument)}>LC</button></td>
                                                        <td>
                                                            <button
                                                                className="verify"
                                                                onClick={() => details(deployment.contractAddress)}
                                                                disabled={buttonClicked}
                                                                style={buttonClicked ? { backgroundColor: 'gray', color: 'white', cursor: 'not-allowed' } : {}}
                                                            >
                                                                More
                                                            </button>
                                                        </td>

                                                        <td><img className="view" onClick={() => viewNfts(deployment.contractAddress)} src={view} /></td>

                                                    </tr>):null
                                                ))}
                                            </tbody>
                                        </table>
                                    </section>
                                </main>
                            </div>
                        ) : (
                            <p className="dashP2">No tienes ningun lanzamiento.
                            </p>
                        )}
                    </div>
                ) : null}

            </div>
            {closeDiv > 0 ?
                (<div class="formMain1">
                    <form>
                        <button onClick={close} class="closeNote">✖</button>
                    </form>
                    <div className="popUpDiv">
                        <div class="rlT"><h1>Requisitos Legales</h1></div>
                        <div class="rlD">{revealedLC}</div>
                    </div>
                </div>) : null}

            {closeDiv2 > 0 ?
                (<div class="formMain1">
                    <form>
                        <button onClick={close} class="closeNote">✖</button>
                    </form>
                    <div className="popUpDiv">
                        <div class="rlT2">Contract Details</div>
                        <div className="MainD">
                            <div className="detailT">Maximum Supply:</div>
                            <div className="detailC">{_maxSupply.toLocaleString('en-US')}</div>
                            <img
                                src={link} onClick={writeL1}
                            />
                        </div>

                        <div className="MainD">
                            <div className="detailT">Creator:</div>
                            <div className="detailC">{_creator}</div>
                        </div>

                        <div className="MainD">
                            <div className="detailT">Current Owner:</div>
                            <div className="detailC">{_owner}</div>
                            <img src={link} onClick={writeL2} />
                        </div>

                        <div className="MainD">
                            <div className="detailT">Collection Name:</div>
                            <div className="detailC">{_collectionName}</div>
                        </div>

                        <div className="MainD">
                            <div className="detailT">Collection Symbol:</div>
                            <div className="detailC">{_collectionSymbol}</div>
                        </div>

                        <div className="MainD">
                            <div className="detailT">Minted Amount:</div>
                            <div className="detailC">{_totalSupply.toLocaleString('en-US')}</div>
                        </div>

                        <div className="MainD">
                            <div className="detailT">Max Per Wallet:</div>
                            <div className="detailC">{_max_per_wallet.toLocaleString('en-US')}</div>
                            <img src={link} onClick={writeL3} />
                        </div>

                        <div className="MainD">
                            <div className="detailT">Price:</div>
                            <div className="detailC">{_publicSaleCost / 10 ** 18} {_currencyType}</div>
                            <img src={link} onClick={writeL4} />

                        </div>

                        <div className="MainD">
                            <div className="detailT">Mint Status:</div>
                            <div className="detailC">{_public_mint_status}</div>
                            <img src={link} onClick={writeL5} />

                        </div>


                    </div>
                </div>) : null}


            <div id="modalContainer" class="modal-container">
                <span class="close">&times;</span>

                <div class="modal-content">
                    <div id="imageContainer"></div>
                </div>
            </div>

            <Footer />

        </div>
    );
}

export default NFTDashboardSP;
