import React, { useState, useEffect } from "react";
import { ethers, utils } from 'ethers';
import '../pages/main.css';
import { paymentContractAddressPolygon } from '../config';
import { paymentContractAddressEth } from '../config';
import { paymentContractAddressAvax } from '../config';
import { paymentContractAddressBnb } from '../config';
import { paymentContractAddressFtm } from '../config';
import { paymentContractAddressArb } from '../config';
import { paymentContractAddressOp } from '../config';
import { paymentContractAddressBase } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import Web3Modal from "web3modal";
import { Link } from 'react-router-dom';
import axios from 'axios';
import Footer from "../components/footer";
import eth from '../images/eth.png';
import matic from '../images/matic.png';
import avax from '../images/avax.png';
import bnb from '../images/bnb.png';
import ftm from '../images/ftm.png';
import arb from '../images/arb.png';
import op from '../images/op.png';
import base from '../images/base.png';
import { Button, useNotification, Loading } from '@web3uikit/core';

const Dashboard = () => {
    const [deployments, setDeployments] = useState([]);
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();
    const [response, setResponse] = useState('');
    const [revealedLC, setRevealedLC] = useState('');
    const [closeDiv, setCloseDiv] = useState(0);
    const [_navbarOpen, set_navbarOpen] = useState(0);
    const [_chainName, setChainName] = useState();
    const [_rpcUrls, set_rpcUrls] = useState();
    const [_blockExpolrerUrls, set_blockExplorerURL] = useState();
    const [_decimals, set_decimals] = useState();
    const [_symbol, set_symbol] = useState();
    const [_selectedChain, set_chain] = useState();
    const [contractPayment, setcontract] = useState('');
    const [_connectPressed, set_connectPressed] = useState(0);
    const [_displayChain, set_displayChain] = useState();
    const [selectedImage, setSelectedImage] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const notification = useNotification();
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [closeDiv2, setCloseDiv2] = useState(0);

    const [_creator, setCreator] = useState(0);
    const [_collectionName, setCollectionName] = useState(0);
    const [_collectionSymbol, setCollectionSymbol] = useState(0);
    const [_owner, setOwner] = useState(0);
    const [_totalSupply, set_totalSupply] = useState(0);
    const [_currentContractAddress, setCurrentContractAddress] = useState("");
    const [_decimalsT, setDecimals] = useState(0);

    const warningNotification = () => {
        notification({
            type: 'warning',
            message: 'Change network to Polygon Mumbai to visit this site',
            title: 'Switch to Polygon Mumbai Test Network',
            position: 'topR'
        });
    };

    const infoNotification = (accountNum) => {
        notification({
            type: 'info',
            message: accountNum,
            title: `Connected to ${_chainName}`,
            position: 'topR'
        });
    };

    useEffect(() => {

        if (!provider) {
            // window.open("https://metamask.app.link/dapp/kudeta.io/");
            //window.location.replace("https://metamask.io");

            // Render something or redirect as needed
            console.log("provider :" + provider);
        } else {

            const handleAccountsChanged = (accounts) => {
                if (provider.chainId == _selectedChain) {
                    infoNotification(accounts[0]);
                }
                // Just to prevent reloading for the very first time
                /*if (JSON.parse(localStorage.getItem('activeAccount')) != null) {
                  setTimeout(() => { window.location.reload() }, 3000);
                }*/
            };

            const handleChainChanged = (chainId) => {
                const hexChainId = utils.hexValue(_selectedChain); // "0x01"

                console.log("chainId :" + chainId);
                console.log("hexChainId :" + hexChainId);

                if (chainId != hexChainId) {


                    window.location.reload();
                }

            }

            const handleDisconnect = () => {
            };

            provider.on("accountsChanged", handleAccountsChanged);
            provider.on("chainChanged", handleChainChanged);
            provider.on("disconnect", handleDisconnect);

            selectedNetwork();

        }

    }, []);

    const disconnect = async () => {
    }

    async function details(contractAddress) {
        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);
            const signer = provider.getSigner();
            const signerAddress = await signer.getAddress();
            setSignerAddress(signerAddress);

            setCurrentContractAddress(contractAddress);
            const contract = new ethers.Contract(contractAddress, TwitterAbi.userABIToken, signer);

            const creator = await contract._creator();
            setCreator(creator);

            const collectionName = await contract.name();
            setCollectionName(collectionName);

            const collectionSymbol = await contract.symbol();
            setCollectionSymbol(collectionSymbol);

            const owner = await contract.owner();
            setOwner(owner);

            const totalSupply = await contract.totalSupply();
            set_totalSupply(Number(totalSupply));

            const decimalsT = await contract.decimals();
            setDecimals(Number(decimalsT));



            // Call the openDiv function after setting maxSupply
            openDiv();

        } catch (err) {
            console.log(err)
        }
    }

    async function openDiv() {
        // After setting the state, _maxSupply should be accessible

        // If you need to use _maxSupply somewhere, you can use it here
        setCloseDiv2(1);
    }


    const checkEligibility = async () => {

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);

            //  let provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const signerAddress = await signer.getAddress();
            setSignerAddress(signerAddress);

            const getnetwork = await provider.getNetwork();
            console.log("getnetwork :" + getnetwork.chainId);


            if (getnetwork.chainId == "1") {

                // setcontract(paymentContractAddressEth);
                const contract = new ethers.Contract(paymentContractAddressEth, TwitterAbi.abi, signer);
                const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
                setIsEligibleToDeploy(isEligibleToDeploy);
                console.log("isEligibleToDeploy : " + isEligibleToDeploy);
                set_chain(1);

            }

            if (getnetwork.chainId == "137") {

                //setcontract(paymentContractAddressPolygon);
                const contract = new ethers.Contract(paymentContractAddressPolygon, TwitterAbi.newABI, signer);
                const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
                setIsEligibleToDeploy(isEligibleToDeploy);
                console.log("isEligibleToDeploy : " + isEligibleToDeploy);
                set_chain(137);

            } else {

                console.log("--------")
            }

            //BNB
            if (getnetwork.chainId == "56") {

                //  setcontract(paymentContractAddressBnb);
                const contract = new ethers.Contract(paymentContractAddressBnb, TwitterAbi.abi, signer);
                const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
                setIsEligibleToDeploy(isEligibleToDeploy);
                set_chain(56);

            }

            //OP
            if (getnetwork.chainId == "10") {

                // setcontract(paymentContractAddressOp);
                const contract = new ethers.Contract(paymentContractAddressOp, TwitterAbi.abi, signer);
                const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
                setIsEligibleToDeploy(isEligibleToDeploy);
                set_chain(10);

            }

            //AVAX
            if (getnetwork.chainId == "43114") {

                // setcontract(paymentContractAddressAvax);
                const contract = new ethers.Contract(paymentContractAddressAvax, TwitterAbi.abi, signer);
                const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
                setIsEligibleToDeploy(isEligibleToDeploy);
                set_chain(43114);

            }

            //FTM
            if (getnetwork.chainId == "250") {

                //  setcontract(paymentContractAddressFtm);
                const contract = new ethers.Contract(paymentContractAddressFtm, TwitterAbi.abi, signer);
                const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
                setIsEligibleToDeploy(isEligibleToDeploy);
                set_chain(250);

            }

            //ARB
            if (getnetwork.chainId == "42161") {

                // setcontract(paymentContractAddressArb);
                const contract = new ethers.Contract(paymentContractAddressArb, TwitterAbi.abi, signer);
                const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
                setIsEligibleToDeploy(isEligibleToDeploy);
                set_chain(42161);

            }

            //BASE
            if (getnetwork.chainId == "8453") {

                // setcontract(paymentContractAddressBase);
                const contract = new ethers.Contract(paymentContractAddressBase, TwitterAbi.abi, signer);
                const isEligibleToDeploy = await contract.isEligibleToDeploy(signerAddress);
                setIsEligibleToDeploy(isEligibleToDeploy);
                set_chain(8453);
            }

            console.log("EligibilitycontractPayment : " + contractPayment);

        } catch (err) {
            console.log(err);
        }
    }

    const getLastPaymentRecordIndex = async () => {

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);
            //  let provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const signerAddress = await signer.getAddress();
            setSignerAddress(signerAddress);

            const getnetwork = await provider.getNetwork();
            console.log("getnetwork :" + getnetwork.chainId);


            //ETH
            if (getnetwork.chainId == "1") {

                setcontract(paymentContractAddressEth);

                // console.log("provider.getCode(address) : " + provider.getCode(signerAddress) );
                const contract = new ethers.Contract(paymentContractAddressEth, TwitterAbi.abi, signer);

                const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
                console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
                setLastPaymentRecordIndex(getLastPaymentRecordIndex);

            }

            //POLYGON
            if (getnetwork.chainId == "137") {

                setcontract(paymentContractAddressPolygon);

                // console.log("provider.getCode(address) : " + provider.getCode(signerAddress) );
                const contract = new ethers.Contract(paymentContractAddressPolygon, TwitterAbi.newABI, signer);
                console.log("getLastPaymentRecordIndex : " + paymentContractAddressPolygon);

                const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
                console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
                setLastPaymentRecordIndex(getLastPaymentRecordIndex);

            } else {

                console.log("--------")
            }

            //BNB
            if (getnetwork.chainId == "56") {

                setcontract(paymentContractAddressBnb);
                const contract = new ethers.Contract(paymentContractAddressBnb, TwitterAbi.abi, signer);

                const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
                console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
                setLastPaymentRecordIndex(getLastPaymentRecordIndex);

            }

            //OP
            if (getnetwork.chainId == "10") {

                setcontract(paymentContractAddressOp);
                const contract = new ethers.Contract(paymentContractAddressOp, TwitterAbi.abi, signer);

                const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
                console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
                setLastPaymentRecordIndex(getLastPaymentRecordIndex);
            }

            //AVAX
            if (getnetwork.chainId == "43114") {

                setcontract(paymentContractAddressAvax);
                const contract = new ethers.Contract(paymentContractAddressAvax, TwitterAbi.abi, signer);

                const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
                console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
                setLastPaymentRecordIndex(getLastPaymentRecordIndex);
            }

            //FTM
            if (getnetwork.chainId == "250") {

                setcontract(paymentContractAddressFtm);
                const contract = new ethers.Contract(paymentContractAddressFtm, TwitterAbi.abi, signer);

                const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
                console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
                setLastPaymentRecordIndex(getLastPaymentRecordIndex);
            }

            //ARB
            if (getnetwork.chainId == "42161") {

                setcontract(paymentContractAddressArb);
                const contract = new ethers.Contract(paymentContractAddressArb, TwitterAbi.abi, signer);

                const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
                console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
                setLastPaymentRecordIndex(getLastPaymentRecordIndex);
            }

            //BASE
            if (getnetwork.chainId == "8453") {

                setcontract(paymentContractAddressBase);
                const contract = new ethers.Contract(paymentContractAddressBase, TwitterAbi.abi, signer);

                const getLastPaymentRecordIndex = await contract.getLastPaymentRecordIndex();
                console.log("getLastPaymentRecordIndex : " + getLastPaymentRecordIndex);
                setLastPaymentRecordIndex(getLastPaymentRecordIndex);
            }

        } catch (err) {
            console.log(err);
        }
    }

    const connectWallet = async () => {
        set_connectPressed(1);
        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = _selectedChain;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(chainID),
                                            chainName: _chainName,
                                            rpcUrls: [_rpcUrls],
                                            blockExpolrerUrls: [_blockExpolrerUrls],
                                            nativeCurrency:
                                            {
                                                decimals: _decimals,
                                                symbol: _symbol,
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEth);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygon);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnb);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOp);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtm);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArb);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBase);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    const connectBtn = async () => {
        checkEligibility();
        getLastPaymentRecordIndex();

        set_connectPressed(1);
    }

    const handleImageClick = (imageName) => {
        setSelectedImage(imageName);
        // ... (rest of your logic)
    };

    async function selectedNetwork() {

        const web3Modal = new Web3Modal();
        const connection = await web3Modal.connect();

        let provider = new ethers.providers.Web3Provider(connection);

        const signer = provider.getSigner();
        const signerAddress = await signer.getAddress();
        setSignerAddress(signerAddress);
        console.log("ELSEconnectWallet();");

        const getnetwork = await provider.getNetwork();
        console.log("getnetwork :" + getnetwork);

        if (getnetwork.chainId == "1") {

            setSelectedImage('eth');

        }

        if (getnetwork.chainId == "137") {

            setSelectedImage('matic');

        } else {

            console.log("--------")
        }


        if (getnetwork.chainId == "56") {

            setSelectedImage('bnb');

        }

        if (getnetwork.chainId == "10") {

            setSelectedImage('op');

        }

        if (getnetwork.chainId == "43114") {

            setSelectedImage('avax');

        }

        if (getnetwork.chainId == "250") {

            setSelectedImage('ftm');

        }

        if (getnetwork.chainId == "42161") {

            setSelectedImage('arb');

        }

        if (getnetwork.chainId == "8453") {

            setSelectedImage('base');

        }

        //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

        setIsAuthenticated(true);
        setProvider(provider);

    }

    async function setChainETH(imageName) {

        setSelectedImage(imageName);


        /* set_chain(1);
         setcontract(paymentContractAddressEth);
         setChainName('Ethereum Mainnet');
         set_symbol('ETH');
         set_rpcUrls('https://mainnet.infura.io/v3/');
         set_blockExplorerURL('https://etherscan.io');*/

        /*set_chain(1);
        setChainName('Goerli test network');
        set_symbol('ETH');
        set_rpcUrls('https://goerli.infura.io/v3/');
        set_blockExplorerURL('https://goerli.etherscan.io');*/
        console.log("Eth chain");

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");


            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 1;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(1),
                                            chainName: 'Goerli test network',
                                            rpcUrls: ['https://goerli.infura.io/v3/'],
                                            blockExpolrerUrls: ['https://goerli.etherscan.io'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'ETH',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const web3Modal = new Web3Modal();
                    const connection = await web3Modal.connect();
                    let provider = new ethers.providers.Web3Provider(connection);
                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEth);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygon);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnb);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOp);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtm);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArb);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBase);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    async function setChainPOLY(imageName) {

        setSelectedImage(imageName);

        //set_chain(137/*137*/);
        //setChainName('Mumbai');
        //set_symbol('Matic');
        /*set_rpcUrls('https://polygon-mainnet.infura.io');
        set_blockExplorerURL('https://polygonscan.com/');*/
        //set_rpcUrls('https://rpc-mumbai.maticvigil.com/v1/a3a160abeb239e773a4525d267cb84f3660634ed');
        //set_blockExplorerURL('https://mumbai.polygonscan.com');
        //console.log("setChainPOLY");

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 137;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(137),
                                            chainName: 'Polygon LlamaNodes',
                                            rpcUrls: ['https://polygon-mainnet.infura.io/v3/32edc413e8e247f3acc67e539f70d74a'],
                                            blockExpolrerUrls: ['https://polygonscan.com'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'Matic',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEth);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygon);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnb);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOp);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtm);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArb);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBase);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    async function setChainBNB(imageName) {
        setSelectedImage(imageName);

        /*set_chain(56);
            setChainName('BNB Smart Chain Mainnet');
            set_symbol('BNB');
            set_rpcUrls('https://bsc-dataseed.binance.org/');
            set_blockExplorerURL('https://bscscan.com/');
            console.log("setChainBNB");*/

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 56;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(56),
                                            chainName: 'BNB Chain',
                                            rpcUrls: ['https://bsc-dataseed.binance.org/'],
                                            blockExpolrerUrls: ['https://bscscan.com/'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'BNB',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEth);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygon);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnb);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOp);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtm);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArb);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBase);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    async function setChainOP(imageName) {
        setSelectedImage(imageName);

        /*set_chain(10);
        setChainName('OP Mainnet');
        set_symbol('ETH');
        set_rpcUrls('https://mainnet.optimism.io');
        set_blockExplorerURL('https://optimistic.etherscan.io');
        console.log("setChainOP");*/

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 10;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(10),
                                            chainName: 'OP Mainnet',
                                            rpcUrls: ['https://mainnet.optimism.io'],
                                            blockExpolrerUrls: ['https://optimistic.etherscan.io'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'ETH',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEth);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygon);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnb);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOp);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtm);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArb);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBase);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    async function setChainAVAX(imageName) {
        setSelectedImage(imageName);

        /*set_chain(43114);
        setChainName('Avalanche C-Chain');
        set_symbol('AVAX');
        set_rpcUrls('https://api.avax.network/ext/bc/C/rpc');
        set_blockExplorerURL('https://snowtrace.io');
        console.log("setChainAVAX");*/

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 43114;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(43114),
                                            chainName: 'Avalanche C-Chain',
                                            rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
                                            blockExpolrerUrls: ['https://snowtrace.io'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'Avalanche C-Chain',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEth);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygon);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnb);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOp);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtm);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArb);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBase);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    async function setChainFTM(imageName) {
        setSelectedImage(imageName);

        /*set_chain(250);
        setChainName('Fantom Opera');
        set_symbol('FTM');
        set_rpcUrls('https://rpcapi.fantom.network');
        set_blockExplorerURL('https://ftmscan.com');
        console.log("setChainFTM");*/

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 250;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(250),
                                            chainName: 'Fantom Testnet',
                                            rpcUrls: ['https://rpc.testnet.fantom.network'],
                                            blockExpolrerUrls: ['https://testnet.ftmscan.com'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'FTM',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEth);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygon);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnb);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOp);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtm);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArb);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBase);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    async function setChainARB(imageName) {
        setSelectedImage(imageName);

        /*set_chain(42161);
        setChainName('Arbitrum One Mainnet');
        set_symbol('ETH');
        set_rpcUrls('https://arb1.arbitrum.io/rpc');
        set_blockExplorerURL('https://arbiscan.io/');
        console.log("setChainARB");*/

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 42161;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(42161),
                                            chainName: 'Arbitrum One Mainnet',
                                            rpcUrls: ['https://arb1.arbitrum.io/rpc'],
                                            blockExpolrerUrls: ['https://arbiscan.io/'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'ETH',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEth);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygon);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnb);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOp);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtm);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArb);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBase);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    async function setChainBASE(imageName) {
        setSelectedImage(imageName);

        /*set_chain(8453);
        setChainName('Base');
        set_symbol('ETH');
        set_rpcUrls('https://developer-access-mainnet.base.org');
        set_blockExplorerURL('https://basescan.org');
        console.log("setChainBASE");*/

        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();

            console.log("connectWallet();");

            if (typeof window.ethereum !== 'undefined') {

                let provider = new ethers.providers.Web3Provider(connection);
                const getnetwork = await provider.getNetwork();

                const chainID = 8453;

                if (getnetwork.chainId != chainID) {

                    try {
                        await provider.provider.request({
                            method: "wallet_switchEthereumChain",
                            params: [{ chainId: utils.hexValue(chainID) }]
                        }).then(() => window.location.reload());
                    } catch (switchError) {
                        if (switchError.code === 4902) {
                            try {
                                await provider.provider.request({
                                    method: "wallet_addEthereumChain",
                                    params: [
                                        {
                                            chainId: utils.hexValue(8453),
                                            chainName: 'Base',
                                            rpcUrls: ['https://developer-access-mainnet.base.org'],
                                            blockExpolrerUrls: ['https://basescan.org'],
                                            nativeCurrency:
                                            {
                                                decimals: 18,
                                                symbol: 'ETH',
                                            }
                                        }
                                    ]
                                }.then(() => window.location.reload()));

                            } catch (addError) {
                                throw addError;
                            }
                        }
                    }
                } else {

                    const signer = provider.getSigner();
                    const signerAddress = await signer.getAddress();
                    setSignerAddress(signerAddress);
                    console.log("ELSEconnectWallet();");

                    const getnetwork = await provider.getNetwork();
                    console.log("getnetwork :" + getnetwork);


                    if (getnetwork.chainId == "1") {

                        setcontract(paymentContractAddressEth);

                    }

                    if (getnetwork.chainId == "137") {

                        setcontract(paymentContractAddressPolygon);

                    } else {

                        console.log("--------")
                    }


                    if (getnetwork.chainId == "56") {

                        setcontract(paymentContractAddressBnb);

                    }

                    if (getnetwork.chainId == "10") {

                        setcontract(paymentContractAddressOp);

                    }

                    if (getnetwork.chainId == "43114") {

                        setcontract(paymentContractAddressAvax);

                    }

                    if (getnetwork.chainId == "250") {

                        setcontract(paymentContractAddressFtm);

                    }

                    if (getnetwork.chainId == "42161") {

                        setcontract(paymentContractAddressArb);

                    }

                    if (getnetwork.chainId == "8453") {

                        setcontract(paymentContractAddressBase);

                    }

                    //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abi, signer);

                    setIsAuthenticated(true);
                    setProvider(provider);

                }


            } else {
                window.open("https://metamask.app.link/dapp/kudeta.io/");
            }
        } catch (err) {

            console.log(err);

        }
    }

    async function verify(contractAddress) {
        try {
            console.log("contractAddress : " + contractAddress);
            const verifyResponse = await axios.post('http://localhost:3001/verify',
                {
                    contractAddress: contractAddress
                });

            if (verifyResponse.data == 'Verified successfully') {
                setResponse('Contract Verified successfully.');
            } else {
                setResponse('Contract Verified Failed.');

            }


        } catch (err) {
            console.log(err)
        }
    }

    async function lcReveal(reveal) {
        try {

            setRevealedLC(reveal);
            setCloseDiv(1);
        } catch (err) {
            console.log(err)
        }
    }

    async function close() {
        try {

            setCloseDiv(0);
            setCloseDiv2(0);
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        // Function to fetch deployment data
        const getAllPayments = async () => {
            try {

                const web3Modal = new Web3Modal();
                const connection = await web3Modal.connect();
                const provider = new ethers.providers.Web3Provider(connection);
                //  let provider = new ethers.providers.Web3Provider(window.ethereum);
                const signer = provider.getSigner();
                const signerAddress = await signer.getAddress();
                setSignerAddress(signerAddress);

                const getnetwork = await provider.getNetwork();
                console.log("getnetwork :" + getnetwork.chainId);
                setChainName(getnetwork.chainId);


                if (getnetwork.chainId == "1") {

                    const contract = new ethers.Contract(paymentContractAddressEth, TwitterAbi.abi, signer);
                    const userPayments = await contract.getAllPayments();
                    console.log("userPayments :" + userPayments[0].timeDeployed);
                    setDeployments(userPayments);
                    const requisitosLegales = await contract.readLegalDocument();

                }

                if (getnetwork.chainId == "137") {

                    const contract = new ethers.Contract(paymentContractAddressPolygon, TwitterAbi.newABI, signer);
                    const userPayments = await contract.getAllPayments();
                    console.log("userPayments :" + userPayments[0].timeDeployed);
                    setDeployments(userPayments);
                    const requisitosLegales = await contract.readLegalDocument();

                }

                if (getnetwork.chainId == "56") {

                    const contract = new ethers.Contract(paymentContractAddressBnb, TwitterAbi.abi, signer);
                    const userPayments = await contract.getAllPayments();
                    console.log("userPayments :" + userPayments[0].timeDeployed);
                    setDeployments(userPayments);
                    const requisitosLegales = await contract.readLegalDocument();

                }

                if (getnetwork.chainId == "10") {

                    const contract = new ethers.Contract(paymentContractAddressOp, TwitterAbi.abi, signer);
                    const userPayments = await contract.getAllPayments();
                    console.log("userPayments :" + userPayments[0].timeDeployed);
                    setDeployments(userPayments);
                    const requisitosLegales = await contract.readLegalDocument();

                }

                if (getnetwork.chainId == "43114") {

                    const contract = new ethers.Contract(paymentContractAddressAvax, TwitterAbi.abi, signer);
                    const userPayments = await contract.getAllPayments();
                    console.log("userPayments :" + userPayments[0].timeDeployed);
                    setDeployments(userPayments);
                    const requisitosLegales = await contract.readLegalDocument();

                }

                if (getnetwork.chainId == "250") {

                    const contract = new ethers.Contract(paymentContractAddressFtm, TwitterAbi.abi, signer);
                    const userPayments = await contract.getAllPayments();
                    console.log("userPayments :" + userPayments[0].timeDeployed);
                    setDeployments(userPayments);
                    const requisitosLegales = await contract.readLegalDocument();

                }

                if (getnetwork.chainId == "42161") {

                    const contract = new ethers.Contract(paymentContractAddressArb, TwitterAbi.abi, signer);
                    const userPayments = await contract.getAllPayments();
                    console.log("userPayments :" + userPayments[0].timeDeployed);
                    setDeployments(userPayments);
                    const requisitosLegales = await contract.readLegalDocument();

                }

                if (getnetwork.chainId == "8453") {

                    const contract = new ethers.Contract(paymentContractAddressBase, TwitterAbi.abi, signer);
                    const userPayments = await contract.getAllPayments();
                    console.log("userPayments :" + userPayments[0].timeDeployed);
                    setDeployments(userPayments);
                    const requisitosLegales = await contract.readLegalDocument();

                }

                /*   const result = await Promise.all(userPayments.map(async (d,index) => {
   
                   let item = {
                       companyId: d[0].companyId,
                   };
   
                   console.log("contractName : " + item.contractName);
                   return item;
   
               }));
   
               setDeployments(result.reverse());
   */

            } catch (error) {
                console.error("Error fetching deployments:", error);
            }
        };

        // Call the function to fetch data when the component mounts
        getAllPayments();
    }, []); // Empty dependency array to run the effect only once when the component mounts

    async function closeNav() {
        set_navbarOpen(0);
        //this.setState({ _navbarOpen: 0 });
    }

    async function navbarOpen() {
        set_navbarOpen(1);
        //this.setState({ _navbarOpen: 0 });
    }


    async function addToMetaMask(tokenAddress, tokenSymbol, tokenDecimals, tokenImage) {
        try {
            // Check if MetaMask is installed
            if (window.ethereum) {
                // Request access to MetaMask accounts
                await window.ethereum.request({ method: 'eth_requestAccounts' });

                // Add the token to MetaMask
                await window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20',
                        options: {
                            address: tokenAddress,
                            symbol: tokenSymbol,
                            decimals: tokenDecimals,
                            image: tokenImage,
                        },
                    },
                });

                // Notify user of successful token addition
                alert('Token added to MetaMask!');
            } else {
                // MetaMask is not installed or not available
                alert('MetaMask is not installed or not available.');
            }
        } catch (error) {
            console.error('Error adding token to MetaMask:', error);
            alert('Error adding token to MetaMask. Please check the console for details.');
        }
    }

    // Call this function with the token details when the user clicks the button
    async function addTokenToMetaMask() {
        const tokenAddress = 'YOUR_TOKEN_ADDRESS';
        const tokenSymbol = 'TOKEN_SYMBOL';
        const tokenDecimals = 18; // Specify the decimals of your token
        const tokenImage = 'URL_TO_TOKEN_IMAGE'; // Optional: Provide a URL to the token image

        await addToMetaMask(tokenAddress, tokenSymbol, tokenDecimals, tokenImage);
    }

    // Assuming you have Web3.js and MetaMask injected into your web page

    // Function to handle adding the token
    function addTokenToMetaMask() {
        // Check if MetaMask is installed
        if (window.ethereum && window.ethereum.isMetaMask) {
            // Token contract address
            const tokenAddress = _currentContractAddress; // Replace with your token contract address

            // Token symbol and decimal places (adjust as per your token)
            const tokenSymbol = _symbol;
            const tokenDecimals = 18; // Replace with your token decimals

            // Call the MetaMask token add method
            window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: tokenAddress,
                        symbol: tokenSymbol,
                        decimals: tokenDecimals,
                        image: 'TOKEN_IMAGE_URL', // Replace with your token image URL
                    },
                },
            })
                .then((success) => {
                    if (success) {
                        console.log(`${tokenSymbol} token added to MetaMask!`);
                    } else {
                        console.error(`Failed to add ${tokenSymbol} token to MetaMask.`);
                    }
                })
                .catch((error) => {
                    console.error('Error adding token to MetaMask:', error);
                });
        } else {
            console.error('MetaMask not found. Please install MetaMask to add tokens.');
        }
    }

    return (
        <div class="dashMain">
            <div class="light2">

                <div class="headers2">

                    <div class="h2">
                        <div class="logoDiv">
                            <span class="dashH" onClick={() => window.location.href = '/'}>Tokeniza</span>
                        </div>

                        <button class="connectBtn" onClick={() => window.location.href = '/'}>HOME</button>

                    </div>

                </div>

                <div class="connectNetworks2">
                    <div class="network">
                        {/*<img
                            onClick={() => setChainETH('eth')}
                            src={eth}
                            className={selectedImage === 'eth' ? 'selected' : ''}
    />*/}
                        <img
                            onClick={() => setChainPOLY('matic')}
                            src={matic}
                            className={selectedImage === 'matic' ? 'selected' : ''}
                        />
                        <img
                            onClick={() => setChainAVAX('avax')}
                            src={avax}
                            className={selectedImage === 'avax' ? 'selected' : ''}
                        />
                        <img
                            onClick={() => setChainBNB('bnb')}
                            src={bnb}
                            className={selectedImage === 'bnb' ? 'selected' : ''}
                        />
                        <img
                            onClick={() => setChainFTM('ftm')}
                            src={ftm}
                            className={selectedImage === 'ftm' ? 'selected' : ''}
                        />
                        <img
                            onClick={() => setChainARB('arb')}
                            src={arb}
                            className={selectedImage === 'arb' ? 'selected' : ''}
                        />
                        <img
                            onClick={() => setChainOP('op')}
                            src={op}
                            className={selectedImage === 'op' ? 'selected' : ''}
                        />
                        <img
                            onClick={() => setChainBASE('base')}
                            src={base}
                            className={selectedImage === 'base' ? 'selected' : ''}
                        />
                    </div>
                    <p class="registerT" data-aos="fade-up" data-aos-duration="500"><span class="spanR">Pick a network, click 'Connect,' and see all your deployments. It's that easy!</span></p>
                    {_connectPressed > 0 ?
                        (<button class="connectReg">Connected</button>) :
                        (<button class="connectReg" onClick={connectBtn}>Connect</button>)
                    }
                </div>

                {(_connectPressed > 0) ? (
                    <div>
                        {deployments.length > 0 ? (
                            <div className="mainContent">
                                <main>
                                    <section>
                                        <div className='dashT'>Welcome to your dashboard!</div>
                                        <p className='dashP'>Explore all the intricate details of your deployment right here!</p>
                                    </section>

                                    <section>
                                        <div className='dashT2'>Your Token Deployments</div>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Company Number</th>
                                                    <th>Company Name</th>
                                                    <th>Contract Address</th>
                                                    <th>Requisitos Legales</th>
                                                    <th>Details</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {deployments.map((deployment, index) => (
                                                    deployment.contractAddress != '0x0000000000000000000000000000000000000000' ? (
                                                        <tr key={index}>
                                                            <td>{new Date(deployment.timeDeployed * 1000).toLocaleString()}</td>
                                                            <td>{deployment.companyId}</td>
                                                            <td>{deployment.companyName}</td>
                                                            <td id='font'>
                                                                <a
                                                                    href={
                                                                        selectedImage === 'eth' ? `https://etherscan.io/address/${deployment.contractAddress}` :
                                                                            selectedImage === 'matic' ? `https://polygonscan.com/address/${deployment.contractAddress}` :
                                                                                selectedImage === 'bnb' ? `https://bscscan.com/address/${deployment.contractAddress}` :
                                                                                    selectedImage === 'op' ? `https://optimistic.etherscan.io/address/${deployment.contractAddress}` :
                                                                                        selectedImage === 'avax' ? `https://snowtrace.io/address/${deployment.contractAddress}` :
                                                                                            selectedImage === 'ftm' ? `https://ftmscan.com/address/${deployment.contractAddress}` :
                                                                                                selectedImage === 'arb' ? `https://arbiscan.io/address/${deployment.contractAddress}` :
                                                                                                    selectedImage === 'base' ? `https://basescan.org/address/${deployment.contractAddress}` :
                                                                                                        ''
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    {deployment.contractAddress === '0x0000000000000000000000000000000000000000' ? (
                                                                        <div>Loading... <br />(Please refresh in 1 min)</div>
                                                                    ) : (
                                                                        <div>{deployment.contractAddress}</div>
                                                                    )}
                                                                </a>
                                                            </td>
                                                            <td><button className="verify" onClick={() => lcReveal(deployment.legalDocument)}>LC</button></td>
                                                            <td><button className="verify" onClick={() => details(deployment.contractAddress)}>More</button></td>

                                                        </tr>) : null
                                                ))}
                                            </tbody>
                                        </table>
                                    </section>
                                </main>
                            </div>
                        ) : (
                            <p className="dashP2">You do not have any deployments</p>
                        )}
                    </div>
                ) : null}

            </div>
            {closeDiv > 0 ?
                (<div class="formMain1">
                    <form>
                        <button onClick={close} class="closeNote">✖</button>
                    </form>
                    <div className="popUpDiv">
                        <div class="rlT"><h1>Requisitos Legales</h1></div>
                        <div class="rlD">{revealedLC}</div>
                    </div>
                </div>) : null}

            {closeDiv2 > 0 ?
                (<div class="formMain1">
                    <form>
                        <button onClick={close} class="closeNote">✖</button>
                    </form>
                    <div className="popUpDiv">
                        <div class="rlT2">Contract Details</div>

                        <div className="MainD">
                            <div className="detailT">Creator:</div>
                            <div className="detailC">{_creator}</div>
                        </div>

                        <div className="MainD">
                            <div className="detailT">Collection Name:</div>
                            <div className="detailC">{_collectionName}</div>
                        </div>

                        <div className="MainD">
                            <div className="detailT">Collection Symbol:</div>
                            <div className="detailC">{_collectionSymbol}</div>
                        </div>

                        <div className="MainD">
                            <div className="detailT">Minted Tokens:</div>
                            <div className="detailC">{(_totalSupply / 10 ** 18).toLocaleString('en-US')}</div>
                        </div>

                        <div className="MainD">
                            <div className="detailT">Add to MetaMask:</div>
                            <button className="addmm" onClick={addTokenToMetaMask}>Add Token</button>
                        </div>

                    </div>
                </div>) : null}

            <Footer />

        </div>
    );
}

export default Dashboard;
