import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';

const CompileAndDeployComponent = (props) => {
    const [response, setResponse] = useState('');
    const [_ContractName, set_ContractName] = useState('');
    const [_ContractSymbol, set_ContractSymbol] = useState('');
    const [_TotalSupply, set_TotalSupply] = useState('');
    const [_TokenTransferAddress, set_TokenTransferAddress] = useState('');
    const userAddress = props.userAddress;
    const getLastPaymentRecordIndex = props.getLastPaymentRecordIndex;
    const _selectededNetwork = props._ChainID;
    const navigate = useNavigate();
    const [_Series, set_Series] = useState('');
    const [_OtraInformacion, set_OtraInformacion] = useState('');
    const [_RequisitosLegales, set_RequisitosLegales] = useState('');
    const [errorMessage, setErrorMessage] = useState(""); // State to hold error message
    const [errorMessage1, setErrorMessage1] = useState(""); // State to hold error message
    const [errorMessage2, setErrorMessage2] = useState(""); // State to hold error message
    const [errorMessage3, setErrorMessage3] = useState(""); // State to hold error message
    const [errorMessage4, setErrorMessage4] = useState(""); // State to hold error message

    const isButtonDisabled = !(_ContractName && _Series && _ContractSymbol && _TotalSupply && _TokenTransferAddress);

    useEffect(() => {
        console.log("_ChainID_USEEFFECT : " + _selectededNetwork);
    }, []);

    const handleCompileAndDeploy = async () => {
        try {
            // Trigger the compilation first
            const compileResponse = await axios.post('https://tokeniza.co.uk/api/compile');
            //http://localhost:3001/deploy

            console.log("compileResponse : " + compileResponse.data);

            if (compileResponse.data === 'Compilation successful' || compileResponse.data === 'Contracts are already compiled. Move to deployment') {
                console.log("DEmpleando....");
                setResponse("DEmpleando y verificando....");

                console.log("chainID_CLIENT : " + _selectededNetwork);

                // If compilation is successful, trigger the deployment
                const deployResponse = await axios.post('https://tokeniza.co.uk/api/deploy', {
                    //http://localhost:3001/deploy

                    contractName: _ContractName,
                    series: _Series,
                    contractSymbol: _ContractSymbol,
                    totalSupply: _TotalSupply,
                    tokenTransferAddress: _TokenTransferAddress,
                    userAddress: userAddress,
                    otraInformacion: _OtraInformacion,
                    requisitosLegales: _RequisitosLegales,
                    getLastPaymentRecordIndex: Number(getLastPaymentRecordIndex),
                    selectededNetwork: _selectededNetwork

                });

                if (deployResponse.data === 'Deployment successful') {
                    setResponse('Contract deployed & verified successfully.');
                    await new Promise(resolve => setTimeout(resolve, 3000));
                    navigate(`/token-dashboard-sp`);
                } else {
                    setResponse(`Error during deployment: ${deployResponse.data}`);
                }
            } else {
                setResponse(`Error during compilation: ${compileResponse.data}`);
            }

        } catch (error) {
            console.error('Error during compilation and deployment:', error.message);
            setResponse(`Error during compilation and deployment: ${error.message}`);
        }
    };



    return (

        <div>
            <div class="header">DESPLEGAR</div>
            <div class="registerT">Desbloquee el poder de la personalización y cree su token único aquí, adaptándolo a sus preferencias y necesidades con solo unos pocos clics.</div>
            <div className='mainDiv'>

                <div>

                    <div>
                        <input
                            required
                            className="mintingAmount"
                            type="text"
                            name="_ContractName"
                            placeholder="Nombre del contrato"
                            value={_ContractName}
                            onChange={event => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 10) {
                                    set_ContractName(inputValue);
                                    setErrorMessage(""); // Clear error message if length is valid
                                } else {
                                    setErrorMessage("La longitud excede el máximo permitido (10 caracteres)"); // Set error message
                                }
                            }}
                        />
                        {/* Display error message if it's not empty */}
                        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

                        <input
                            required
                            className="mintingAmount"
                            type="text"
                            name="_Series"
                            placeholder="Serie"
                            value={_Series}
                            onChange={event => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 10) {
                                    set_Series(inputValue);
                                    setErrorMessage1(""); // Clear error message if length is valid
                                } else {
                                    setErrorMessage1("La longitud excede el máximo permitido (10 caracteres)"); // Set error message
                                }
                            }}
                        />
                        {/* Display error message if it's not empty */}
                        {errorMessage1 && <div style={{ color: 'red' }}>{errorMessage1}</div>}

                        <input
                            required
                            className="mintingAmount"
                            type="text"
                            name="_ContractSymbol"
                            placeholder="Símbolo de contrato"
                            value={_ContractSymbol}
                            onChange={event => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 10) {
                                    set_ContractSymbol(inputValue);
                                    setErrorMessage2(""); // Clear error message if length is valid
                                } else {
                                    setErrorMessage2("La longitud excede el máximo permitido (10 caracteres)"); // Set error message
                                }
                            }}
                        />
                        {/* Display error message if it's not empty */}
                        {errorMessage2 && <div style={{ color: 'red' }}>{errorMessage2}</div>}
                    </div>
                    <div>
                        <input
                            required
                            className="mintingAmount"
                            type="number"
                            name="_TotalSupply"
                            placeholder="Oferta total"
                            value={_TotalSupply}
                            onChange={event => {
                                const inputValue = event.target.value;
                                if (inputValue <= 3000000000) { // 3 billion limit
                                    set_TotalSupply(inputValue);
                                    setErrorMessage4(""); // Clear error message if value is valid
                                } else {
                                    setErrorMessage4("La cantidad no puede exceder los 3 mil millones"); // Set error message
                                }
                            }}
                        />
                        {/* Display error message if it's not empty */}
                        {errorMessage4 && <div style={{ color: 'red' }}>{errorMessage4}</div>}
                    </div>

                    <div>
                        <input
                            required
                            className="mintingAmount"
                            type="text"
                            name="_TokenTransferAddress"
                            placeholder="Dirección de transferencia de token"
                            value={_TokenTransferAddress}
                            onChange={event => {
                                const inputValue = event.target.value;
                                if (inputValue.length <= 42) {
                                    set_TokenTransferAddress(inputValue);
                                    setErrorMessage3(""); // Clear error message if length is valid
                                } else {
                                    setErrorMessage3("Dirección de billetera parece estar equivocada"); // Set error message
                                }
                            }}
                        />
                        {/* Display error message if it's not empty */}
                        {errorMessage3 && <div style={{ color: 'red' }}>{errorMessage3}</div>}
                    </div>
                    <input name="_OtraInformacion" placeholder='Otra información' value={_OtraInformacion}
                        onChange={event => set_OtraInformacion(event.target.value)}></input>

                    <label class="mintingAmount2" for="multilineInput">Requisitos legales</label>
                    <div></div>
                    <textarea id="multilineInput" name="inputText" rows="70" placeholder="Escribe tu texto aquí..." value={_RequisitosLegales}
                        onChange={event => set_RequisitosLegales(event.target.value)}></textarea>
                </div>

                <button className='inButton' onClick={handleCompileAndDeploy} disabled={isButtonDisabled}>
                    Compile and Deploy Contract
                </button>

                <div className='note'>{response}</div>

            </div>
        </div >
    );
};

export default CompileAndDeployComponent;
