import React, { useState, useEffect } from 'react';
import TwitterAbi from '../abi/Twitter.json';
import { ethers } from 'ethers';
import { Avatar, Loading, useNotification } from "@web3uikit/core";
import Web3Modal from "web3modal";
import '../pages/main.css';
import close from '../images/close.png';
import { utils } from 'ethers';

var mailtoLink = require('mailto-link');

const handle = () => {
	mailtoLink({ to: 'admin@tokeniza.mx', subject: 'Nice mailto lib' });
	console.log("MailCode");
}


const NFTRegister = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [response, setResponse] = useState('');
  const [_ContractName, set_ContractName] = useState('');
  const [_ContractSymbol, set_ContractSymbol] = useState('');
  const [_TotalSupply, set_TotalSupply] = useState('');
  const [_TokenTransferAddress, set_TokenTransferAddress] = useState('');
  const [_companyId, set_CompanyId] = useState('');
  const [_companyName, set_CompanyName] = useState('');
  const [_email, set_Email] = useState('');
  const [_signerAddress, setSignerAddress] = useState('');
  const contractPayment = props.contractPayment;
  const [_btnOpen, set_btnOpen] = useState(0);
  const _selectedChain = props._selectedChain;
  const [provider, setProvider] = useState(window.ethereum);
  const _chainName = props._chainName;
  const _rpcUrls = props._rpcUrls;
  const _blockExpolrerUrls = props._rpcUrls;
  const _decimals = props._decimals;
  const _symbol = props._symbol;
  const notification = useNotification();
  const [deployedContractsCount, setDeployedContractsCount] = useState(0);
  const [eligibilityNumbers, setEligibilityNumbers] = useState(0);
  const [_currentContractAddress, setCurrentContractAddress] = useState("");
  const [showRegisterForm, setShowRegisterForm] = useState(false);

  useEffect(() => {

    if (!provider) {
      // window.open("https://metamask.app.link/dapp/kudeta.io/");
      //window.location.replace("https://metamask.io");

      // Render something or redirect as needed
    } else {
      connectWallet();

      const handleAccountsChanged = (accounts) => {
        if (provider.chainId == _selectedChain) {
          infoNotification(accounts[0]);
        }
        // Just to prevent reloading for the very first time
        if (JSON.parse(localStorage.getItem('activeAccount')) != null) {
          setTimeout(() => { window.location.reload() }, 3000);
        }
      };

      const handleChainChanged = (chainId) => {
        if (chainId != _selectedChain) {

        }
        window.location.reload();
      }

      const handleDisconnect = () => { };


      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

    }

  }, []);

  async function details() {
    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      const signer = provider.getSigner();
      const signerAddress = await signer.getAddress();
      setSignerAddress(signerAddress);

      const contract = new ethers.Contract("0x08bd700c054035d518BbC006f763ca2003F68d5f", TwitterAbi.abi, signer);

      const deployedContractsCount = await contract.deployedContractsCount("0xbBbE385bD75627B245401450cdb7c48eC7A454d7");
      setDeployedContractsCount(deployedContractsCount);
      console.log("deployedContractsCount : " + Number(deployedContractsCount));

      const eligibilityNumbers = await contract.eligibilityNumbers("0xbBbE385bD75627B245401450cdb7c48eC7A454d7");
      setEligibilityNumbers(eligibilityNumbers);
      console.log("eligibilityNumbers : " + Number(eligibilityNumbers));

      if (deployedContractsCount + 2 <= eligibilityNumbers) {
        if (signerAddress == "0xbBbE385bD75627B245401450cdb7c48eC7A454d7") {
          setShowRegisterForm(true);
        } else {
          setShowRegisterForm(false);
        }
      } else {
        setShowRegisterForm(true);
      }

    } catch (err) {
      console.log(err)
    }
  }

  const infoNotification = (accountNum) => {
    notification({
      type: 'info',
      message: accountNum,
      title: `Connected to ${_chainName}`,
      position: 'topR'
    });
  };


  const connectWallet = async () => {

    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();

      if (typeof window.ethereum !== 'undefined') {

        let provider = new ethers.providers.Web3Provider(connection);
        const getnetwork = await provider.getNetwork();

        const chainID = _selectedChain;

        if (getnetwork.chainId != chainID) {
          try {
            await provider.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: utils.hexValue(chainID) }]
            }).then(() => window.location.reload());
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await provider.provider.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: utils.hexValue(chainID),
                      chainName: _chainName,
                      rpcUrls: [_rpcUrls],
                      blockExpolrerUrls: [_blockExpolrerUrls],
                      nativeCurrency:
                      {
                        decimals: _decimals,
                        symbol: _symbol,
                      }
                    }
                  ]
                }.then(() => window.location.reload()));
              } catch (addError) {
                throw addError;
              }
            }
          }
        } else {

          const signer = provider.getSigner();
          const signerAddress = await signer.getAddress();
          setSignerAddress(signerAddress);

          const contract = new ethers.Contract(contractPayment, TwitterAbi.abiNFT, signer);

          setIsAuthenticated(true);
          setProvider(provider);

        }

      } else {
        window.open("https://metamask.app.link/dapp/kudeta.io/");
      }
    } catch (err) {

      console.log(err);

    }

    details();

  }

  async function paymentDeploy() {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    // let provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const signerAddress = await signer.getAddress();
    setSignerAddress(signerAddress);


    //........................................................//

    try {
      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();

      if (typeof window.ethereum !== 'undefined') {

        let provider = new ethers.providers.Web3Provider(connection);
        const getnetwork = await provider.getNetwork();
        const chainID = _selectedChain;

        if (getnetwork.chainId != chainID) {
          ;
          try {
            await provider.provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: utils.hexValue(chainID) }]
            }).then(() => window.location.reload());
          } catch (switchError) {
            if (switchError.code === 4902) {
              try {
                await provider.provider.request({
                  method: "wallet_addEthereumChain",
                  params: [
                    {
                      chainId: utils.hexValue(chainID),
                      chainName: _chainName,
                      rpcUrls: [_rpcUrls],
                      blockExpolrerUrls: [_blockExpolrerUrls],
                      nativeCurrency:
                      {
                        decimals: _decimals,
                        symbol: _symbol,
                      }
                    }
                  ]
                }.then(() => window.location.reload()));
              } catch (addError) {
                throw addError;
              }
            }
          }
        } else {
          const signer = provider.getSigner();
          const signerAddress = await signer.getAddress();
          setSignerAddress(signerAddress);

          //   const contract = new ethers.Contract(contractPayment, TwitterAbi.abiNFT, signer);
          //  setcontract(contract);

          setIsAuthenticated(true);
          setProvider(provider);

        }
      }
    } catch (err) {

      console.log(err);

    }

    //........................................................//




    try {

      const contract = new ethers.Contract(contractPayment, TwitterAbi.abiNFT, signer);

      const payment = await contract.deployContract(_companyId, _companyName, _email, { value: '100000000000000' });
      await payment.wait();

      notification({
        type: 'success',
        title: 'Registered Successfully',
        position: 'topR'
      });

      await new Promise(resolve => setTimeout(resolve, 3000));
      window.location.reload();

    } catch (error) {
      notification({
        type: 'error',
        title: 'Transaction Error',
        message: error.message,
        position: 'topR'
      });
    }
  }

  async function btnOpen() {
    set_btnOpen(1);


  }


  async function closeNav() {
    set_btnOpen(0);
  }


  return (
    <div>
      {showRegisterForm ?
        <div>

          <div class="header" data-aos="fade-up" data-aos-duration="500">REGISTER</div>
          <div class="registerT" data-aos="fade-up" data-aos-duration="500">Unlock the full potential of our platform by registering here to seamlessly create your personalized token, empowering you with exclusive benefits and functionalities.</div>

          {_btnOpen < 1 ?
            (<button data-aos="fade" data-aos-duration="500" onClick={btnOpen} class="connectBtn3">REGISTER NOW</button>) : <img class="closeI" onClick={closeNav} src={close} />}

          {_btnOpen > 0 ?
            (<div className='mainDiv'>

              <div>
                <input required class="mintingAmount" type="text" name="_companyId" placeholder="Company Id" value={_companyId}
                  onChange={event => set_CompanyId(event.target.value)}></input>
                <input required class="mintingAmount" type="text" name="_companyName" placeholder="Company Name" value={_companyName}
                  onChange={event => set_CompanyName(event.target.value)}></input>
                <input required class="mintingAmount" type="email" name="_email" placeholder="Email" value={_email}
                  onChange={event => set_Email(event.target.value)}></input>

              </div>

              <button class='inButton' onClick={paymentDeploy}>Register</button>
            </div>) : null}

        </div> :
        <div class="registerT" >Please contact administrator of the website <br /><span className='email' onClick={handle}>admin@tokeniza.mx</span></div>}
    </div>
  );
};

export default NFTRegister;
