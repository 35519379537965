import React, { Component } from 'react';

class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqbg">

        <div class="accordin reveal">

          <div class="aboutT">FAQ about NFT Tokenization in Tokeniza</div>

          <details >
            <summary>What is an NFT?</summary>
            <div class="faq__content">
              <p>An NFT, or Non-Fungible Token, is a type of digital token unique to the blockchain that represents the ownership or authenticity of a digital or physical asset. Unlike cryptocurrencies, each NFT has a unique value and is not directly exchangeable for another NFT.</p>
            </div>
          </details>
          <details>
            <summary>How can I tokenize an asset as an NFT on Tokeniza?</summary>
            <div class="faq__content">
              <p>To tokenize an asset as an NFT on Tokeniza, the process begins with your registration on our platform. Once registered, you'll need to fill out a detailed form about the asset you want to tokenize. For example, if it's a digital artwork, you'll need to provide a description of the artwork, the name of the artist, the year of creation, and attach a high-resolution image of the art. It's also important to define the value you think your asset has, although our team can assist you in the assessment.</p>
            </div>
          </details>
          <details>
            <summary>What kind of assets can I tokenize in NFTs?</summary>
            <div class="faq__content">
              <p>At Tokeniza you can tokenize a wide range of assets, including: </p>
              <p>Digital Artworks: Artists can tokenize their digital works, allowing collectors to acquire exclusive digital property rights.</p>
              <p>Music: Musicians and songwriters can tokenize their tracks or albums, creating a limited edition of their music. </p>
              <p>Videos: Content creators can tokenize videos, from short films to iconic event moments, providing a unique way for followers to own a digitized moment in history.</p>
              <p>Physical Properties and Copyrights: Tokeniza offers the possibility of tokenizing property rights over physical goods and copyrights, opening new avenues for investment and value distribution.</p>
            </div>
          </details>

          <details>
            <summary>WHO CAN LEASE AN NFT OF A WORK
              OF ART MARKETED BY Tokeniza</summary>
            <div class="faq__content">
              <p>Any person or entity that has a screen with an internet connection that wants
                lease an NFT of a work of art previously authorized by Tokeniza.</p>
            </div>
          </details>

          <details>
            <summary>Are NFTs safe on Tokeniza?</summary>
            <div class="faq__content">
              <p>Security is a priority at Tokeniza. We use blockchain technology to ensure that each NFT is authentic and its ownership is clearly defined and protected. In addition, we implement additional security measures to protect our users' assets and information.</p>
            </div>
          </details>

        </div>
      </div>


    )
  }
}

export default FAQ;

