import React, { useState, useEffect } from 'react';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import moneymanagement from '../images/money-management.png';
import telegram from '../images/telegram.png';
import twitter from '../images/twitter.png';
import linkedin from '../images/linkedin.png';
import mail from '../images/email.png';
import yt from '../images/youtube.png';
import discord from '../images/discord.png';
import crypto from '../images/logo2.png';

const SocialMedia = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    return (
        <>
            <div class="socialMain">

                <div class="header">Únete a nuestra comunidad</div>

                <div class="subBox">
                    <div class="subBox2">
                        <div class="mainT">SUSCRÍBETE A NUESTRO <br /> BOLETÍN DE NOTICIAS</div>
                        <div class="mainT2">y obtén un 10% de descuento en Token Tool</div>
                        <button class="connectBtn2">Suscríbase Ahora</button>
                    </div>

                    <img src={crypto} />
                </div>

                <div class="benefitsMain2" data-aos="fade" data-aos-duration="500">
                    <div class="socialMain2">
                        <div class="s2">
                            <img src={twitter} />
                            <div>
                                <div class="subT">Twitter</div>
                                <div class="subS">Unete a la communidad</div>
                            </div>
                        </div>
                        <div class="s2">
                            <img src={discord} />
                            <div>
                                <div class="subT">Discord</div>
                                <div class="subS">Ser miembro</div>
                            </div>
                        </div>
                    </div>

                    <div class="socialMain2">
                        <div class="s2">
                            <img src={linkedin} />
                            <div>
                                <div class="subT">Linkedin</div>
                                <div class="subS">Conéctate con nosotros</div>
                            </div>
                        </div>
                        <div class="s2">
                            <img src={yt} />
                            <div>
                                <div class="subT">Youtube</div>
                                <div class="subS">Conviértete en parte de nosotras</div>
                            </div>
                        </div>
                    </div>

                    <div class="socialMain2">
                        <div class="s2">
                            <img src={mail} />
                            <div>
                                <div class="subT">Email</div>
                                <div class="subS">Envía tu mensaje</div>
                            </div>
                        </div>
                        <div class="s2">
                            <img src={telegram} />
                            <div>
                                <div class="subT">Telegram</div>
                                <div class="subS">Unete a la communidad</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    );
};

export default SocialMedia;