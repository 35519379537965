import React, { useState, useEffect } from 'react';
import CompileComponent from './CompileComponent';
import Register from './Register';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import deploy from '../images/useCases.png';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';

const About2SP = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    return (
        <>
            <div class="aboutMain">

                <div class="aboutMain2">

                    <div class="about2" data-aos="fade" data-aos-duration="500">
                        <div class="aboutT">Usos de tokenización</div>
                        <div class="aboutT2">Tokeniza se especializa en la tokenización de</div>
                        <div className='centered'>

                        <p className='aboutH2'>1. Productos financieros</p>
                        <p className='aboutH2'>2. Acciones – capital social de empresas</p>
                        <div class="aboutT2"><li>Bienes raíces</li></div>
                        <div class="aboutT2"><li>Arte </li></div>
                        <div class="aboutT2"><li>Entradas a eventos </li></div>
                        <div class="aboutT2"><li>Cadena de suministro y logística</li></div>
                        <div class="aboutT2"><li>Crowdfunding y capital de riesgo</li></div>
                        <div class="aboutT2"><li>Derechos de Propiedad Intelectual</li></div>
                        </div>
                        <p></p>
                        <p class="aboutT2"><span className='color'>Y más. Contáctanos, analizaremos tu negocio e identificaremos posibles casos de uso de tokenización contruidos a la medida.</span></p>

                    </div>

                    <img class="deploy2" src={deploy} data-aos="zoom-in" data-aos-duration="500" />

                </div>
            </div>

        </>
    );
};

export default About2SP;