import React, { useState, useEffect } from 'react';
import CompileComponent from './CompileComponent';
import Register from './Register';
import '../pages/main.css';
import { paymentContractAddress } from '../config';
import TwitterAbi from '../abi/Twitter.json';
import { Button, useNotification, Loading } from '@web3uikit/core';
import Web3Modal from "web3modal";
import { ethers, utils } from 'ethers';
import moneymanagement from '../images/money-management.png';
import search from '../images/search.png';
import rise from '../images/rise.png';
import usd from '../images/usd.png';
import system from '../images/system.png';
import wallet from '../images/wallet.png';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Benefits = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [paymentMade, setPaymentMade] = useState(false);
    const notification = useNotification();
    const [loading, setLoadingState] = useState(false);
    const [_isEligibleToDeploy, setIsEligibleToDeploy] = useState(false);
    const [_getLastPaymentRecordIndex, setLastPaymentRecordIndex] = useState();
    const [provider, setProvider] = useState(window.ethereum);
    const [_signerAddress, setSignerAddress] = useState();

    const handlePaymentMade = () => {
        setPaymentMade(true);
    };

    return (
        <>
            <div class="benefitsMain">

                <div class="header">Centrarse en los casos de uso de tokenización en lugar de la codificación</div>

                <div class="benefitsMain2" data-aos="fade" data-aos-duration="500">
                    <div class="b1">
                        <div><img src={moneymanagement}/><span className='bB'>Tecnología de punta:</span><br/>Token Tool es líder en la creación y gestión de tokens</div>
                        <div><img src={system}/><span className='bB'>Experiencia de usuario simplificada:</span> <br/>Usa nuestra plataforma o una API para una interacción automática (sólo para empresas)</div>
                    </div>

                    <div class="b1">
                        <div><img src={wallet}/><span className='bB'>Pago fácil y seguro:</span><br/>Paga sólo por lo que usas directo de tu wallet (no se necesita suscripción ni tarjeta de crédito)</div>
                        <div><img src={usd}/><span className='bB'>Fácil de usar:</span> <br/>Crea, administra y gestiona tokens ERC20 y ERC721 fácilmente</div>
                    </div>

                    <div class="b1">
                        <div><img src={search}></img><span className='bB'>Contratos inteligentes:</span><br/>Usa nuestros contratos inteligentes validados por expertos, en lugar de codificar los tuyos</div>
                        <div><img src={rise}/><span className='bB'>Versión empresarial:</span><br/>Contáctanos para obtener la versión empresarial de Token Tool</div>
                    </div>

                </div>
            </div>

        </>
    );
};

export default Benefits;